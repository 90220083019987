import {routes} from "../../../config/routes";
import LandingDesktopTabletFooterItem from "../../items/landingScreen/LandingDesktopTabletFooterItem";

function LandingDesktopTabletSideContentNavigation() {
    // Used in LandingDesktopTabletLowerFooter to display list
    // of side content navigation link

    // BUILD
    return (
        <div className={"w-full flex justify-end space-x-24"}>
            <LandingDesktopTabletFooterItem label={"À propos"}
                                          route={routes.about} />
            <LandingDesktopTabletFooterItem label={"Mentions légales"}
                                          route={routes.legals} />
            <LandingDesktopTabletFooterItem label={"Politique de confidentialité"}
                                          route={routes.privacy} />
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletSideContentNavigation