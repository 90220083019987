import {isNullOrUndefined} from "../../services/Validator";

function SystemCollapsableMenuItem({label, icon, onPress, isMasterAction}) {
    // Used through the app to provide navigation
    // item in collapsable menu

    // BUILD
    if (isNullOrUndefined(label) || isNullOrUndefined(icon))
        return null
    return (
        <div className={`cursor-pointer w-full flex items-center justify-start space-x-8 px-12 py-8 ${isMasterAction ? "hover:text-primaryLight1" : "hover:text-white"} text-greyLight9`}
             onClick={onPress} >
            <i className={`material-icons text-[22px]`}>
                {icon}
            </i>
            <span className={"font-medium"}>
                {label}
            </span>
        </div>
    );
}

// EXPORT
export default SystemCollapsableMenuItem