import {api} from "./routes";
import {generatePath} from "react-router";
import {makeRequest, Methods} from "./request";

// List of methods triggering questions related api calls

// ---- Get levels
// export function get_grades() {
//     const url = api.domain + generatePath(api.routes.grades)
//     return makeRequest(url, Methods.GET, null)
// }

// ---- Get quiz open levels
export function get_flash_grades() {
    const url = api.domain + generatePath(api.routes.flashGrades)
    return makeRequest(url, Methods.GET)
}