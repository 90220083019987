
function ParameterUnselectedGrade() {
    // Used in ParameterSubthemesContent to display

    // BUILD
    return (
        <div className={"animate-appear w-full h-full flex flex-col space-y-12 justify-center items-center shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[0px] sm:rounded-[16px] px-16 mt-16 sm:mt-24"}>
            <div className={`h-[96px] min-h-[96px] sm:h-[96px] sm:min-h-[96px] lg:h-[112px] lg:min-h-[112px]`}>
                <img className={"object-contain h-full"}
                     src={"/images/parameterScreen/penAndPaperBear.png"}
                     alt="Ours avec stylo et cahier" />
            </div>
            <span className={"font-medium text-center text-[16px] lg:text-[18px] text-greyLight7"}>
                {"Commence par sélectionner un niveau"}
            </span>
        </div>
    );
}

// EXPORT
export default ParameterUnselectedGrade