import {isNullOrUndefined} from "../../services/Validator";
import {PictureRatio} from "../../enums/PictureRatio";
import {api} from "../../api/routes";
import {generatePath} from "react-router";


// PROPERTIES
const pictureSize = {
    // ---- Portrait (zoomed is 1.5 desktop)
    mobilePortrait: 'mt-16 w-[220px] min-w-[220px] h-[320px] min-h-[320px]',
    portrait: 'w-[220px] min-w-[220px] h-[320px] min-h-[320px]',
    zoomedPortrait: 'w-[330px] min-w-[330px] h-[480px] min-h-[480px]',
    // ---- Square (zoomed is 1.5 desktop)
    mobileSquare: 'mt-16 w-[320px] min-w-[320px] h-[320px] min-h-[320px]',
    square: 'mt-24 w-[320px] min-w-[320px] h-[320px] min-h-[320px]',
    zoomedSquare: 'w-[480px] min-w-[480px] h-[480px] min-h-[480px]',
    // ---- Landscape (zoomed is 2.0 mobile)
    mobileLandscape: 'mt-16 w-[320px] min-w-[320px] h-[270px] min-h-[270px]',
    landscape: 'mt-24 w-[480px] min-w-[480px] h-[405px] min-h-[405px]',
    zoomedLandscape: 'w-[640px] min-w-[640px] h-[540px] min-h-[540px]',
    // ---- Medium landscape (zoomed is 1.3 desktop)
    mobileMediumLandscape: 'mt-16 w-[320px] min-w-[320px] h-[120px] min-h-[120px]',
    mediumLandscape: 'mt-24 w-[480px] min-w-[480px] h-[180px] min-h-[180px]',
    zoomedMediumLandscape: 'w-[624px] min-w-[624px] h-[234px] min-h-[234px]',
    // ---- Small landscape (zoomed is 1.3 desktop)
    mobileSmallLandscape: 'mt-16 w-[320px] min-w-[320px] h-[60px] min-h-[60px]',
    smallLandscape: 'mt-24 w-[480px] min-w-[480px] h-[90px] min-h-[90px]',
    zoomedSmallLandscape: 'w-[624px] min-w-[624px] h-[117px] min-h-[117px]',
    // ---- Error
    errorSquareOrLandscape: 'mt-[16px] lg:mt-[24px] w-[320px] sm:w-[520px]',
    errorPortrait: 'mt-16 sm:mt-[0px] w-[220px]'
}

// METHODS
// ---- Compute QuestionPicture frame size
export function computeQuestionPictureSize(pictureRatio, isForMobile) {
    if (pictureRatio > 5) {
        return isForMobile ? pictureSize.mobileSmallLandscape : pictureSize.smallLandscape
    } else if (pictureRatio > 2) {
        return isForMobile ? pictureSize.mobileMediumLandscape : pictureSize.mediumLandscape
    } else if (pictureRatio > 1) {
        return isForMobile ? pictureSize.mobileLandscape : pictureSize.landscape
    } else if (pictureRatio === 1) {
        return isForMobile ?  pictureSize.mobileSquare : pictureSize.square
    } else {
        return isForMobile ? pictureSize.mobilePortrait : pictureSize.portrait
    }
}

export function computeZoomedQuestionPictureSize(pictureRatio) {
    if (pictureRatio > 5) {
        return pictureSize.zoomedSmallLandscape
    } else if (pictureRatio > 2) {
        return pictureSize.zoomedMediumLandscape
    } else if (pictureRatio > 1) {
        return pictureSize.zoomedLandscape
    } else if (pictureRatio === 1) {
        return pictureSize.zoomedSquare
    } else {
        return pictureSize.zoomedPortrait
    }
}

// ---- Compute QuestionPicturePlaceholder size
export function computePicturePlaceholderSize(imageRatio, isForMobile) {
    switch (imageRatio) {
        case PictureRatio.PORTRAIT:
            return isForMobile ? pictureSize.mobilePortrait : pictureSize.portrait
        case PictureRatio.SQUARE:
            return isForMobile ?  pictureSize.mobileSquare : pictureSize.square
        case PictureRatio.LANDSCAPE:
            return isForMobile ? pictureSize.mobileLandscape : pictureSize.landscape
        default:
            return isForMobile ?  pictureSize.mobileSquare : pictureSize.square
    }
}

// ---- Check if picture has landscape or square ratio
export function displayLandscapeOrSquare(questionPicture, questionPictureUrl, ratio) {
    if (!isNullOrUndefined(questionPicture) && !isNullOrUndefined(questionPictureUrl) && !isNullOrUndefined(ratio)) {
        return ratio >= 1
    } else {
        return false
    }
}

// ---- Check if picture has landscape or square ratio
export function displayPortrait(questionPicture, questionPictureUrl, ratio) {
    if (!isNullOrUndefined(questionPicture) && !isNullOrUndefined(questionPictureUrl) && !isNullOrUndefined(ratio)) {
        return ratio < 1
    } else {
        return false
    }
}

// ---- Decide which axis the picture should stretch over
export function computePreferredAxis(ratio) {
    return (!isNullOrUndefined(ratio) && ratio >= 1) ? "w-full" : "h-full"
}

// ---- Compute pictureUrl download link
export function computePictureUrl(pictureUrl) {
    // Defensive measure
    if (isNullOrUndefined(pictureUrl))
        return null
    // Build url
    const url = api.domain + generatePath(api.routes.proxyImage)
    return `${url}?pictureUrl=${pictureUrl}`
}

// ---- Size the error container depending on imageRatio
export function computeErrorContainerSize(pictureRatio) {
    if (isNullOrUndefined(pictureRatio)) {
        return pictureSize.errorSquareOrLandscape
    } else {
        return pictureRatio < 1 ? pictureSize.errorPortrait : pictureSize.errorSquareOrLandscape
    }
}