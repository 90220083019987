import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileContentLayout({children}) {
    // Used in LandingScreen to frame content
    // in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow sm:hidden flex flex-col items-center justify-start"}>
            {children}
        </div>
    );
}

// EXPORT
export default LandingMobileContentLayout