
function SystemMobileBurgerMenuIconButton({onPressed}) {
    // Used through the app to display burger menu
    // icon used to open collapsable navigation menu

    // BUILD
    return (
        <div className="animate-appear w-full flex justify-end -mr-4"
             onClick={onPressed}>
            <i className={`material-icons cursor-pointer text-[32px] text-greyLight7 hover:text-greyLight9`}>
                menu
            </i>
        </div>
    );
}

// EXPORT
export default SystemMobileBurgerMenuIconButton