// Api related configuration

// ---- Api related configuration values
export const api = {
    initial_domain: process.env.REACT_APP_API_URL,
    domain: process.env.REACT_APP_API_URL + "/",
    routes: {
        // Grade
        grades: "grades",
        flashGrades: "grades/flash",
        // Image
        proxyImage: "proxyMinio",
        // PageViewMetric
        recordPageViewMetric: "metrics/pageViewMetric/record",
        // Questions
        singleQuestion: "question/:id",
        leveledQuestions: "questions/:id",
        questionsFromLevelAsIds: 'questions',
        // Subtheme
        subthemesWithLevelAsForGrade: "subthemes/levelAs/:id",
        subthemesWithLevelAsForGradeAndTheme: "subthemes/levelAs",
        // Theme
        themes: "themes",
    }
}