import {get_nested} from "../../../services/JsonHandler";
import QuestionsMobileCardHeaderSubText from "../../texts/questionsStepScreen/QuestionsMobileCardHeaderSubText";
import CorrectionQuestionIndex from "../correctionStepScreen/CorrectionQuestionIndex";

function QuestionsMobileCardHeader({question, questionIndex, questionCorrectness}) {
    // Used in QuestionsMobileCard to display header with
    // both question subtheme and level

    // PROPERTIES
    const questionTask = get_nested(['levelA', 'task'], question, null)

    // BUILD
    return (
        <div className={"w-full flex justify-between items-center py-10 px-12"}>
            <CorrectionQuestionIndex number={questionIndex}
                                     correctness={questionCorrectness} />
            <QuestionsMobileCardHeaderSubText task={questionTask} />
        </div>
    );
}

// EXPORT
export default QuestionsMobileCardHeader