import SystemOptionsCard from "../../cards/SystemOptionsCard";
import {get_nested} from "../../../services/JsonHandler";
import CorrectionMobileBottomBar from "../../bars/correctionStepScreen/CorrectionMobileBottomBar";
import CorrectionMobileContentLayout from "../../layouts/correctionStepScreen/CorrectionMobileContentLayout";
import {isNullOrUndefined} from "../../../services/Validator";
import SystemQuestionPicture from "../../images/SystemQuestionPicture";
import QuestionsMobileQuestionImageLayout from "../../layouts/questionsStepScreen/QuestionsMobileQuestionImageLayout";
import SystemQuestionCardQueryText from "../../texts/SystemQuestionCardQueryText";
import CorrectionMobileHeader from "./CorrectionMobileHeader";
import SystemQuestionKnowledge from "../SystemQuestionKnowledge";

function CorrectionMobileContent({question, questionIndex, handleClose}) {
    // Used in both CorrectionStepScreen and PreviewQuestionScreen to display
    // Question meta info, knowledge and options

    // PROPERTIES
    const questionCorrectness = question?.selectedOption?.isCorrect
    const questionQuery = get_nested(['query'], question, null)
    const questionKnowledge = get_nested(['levelA', 'levelAKnowledge'], question, null)
    const questionPictureUrl = get_nested(['questionPicture', 'filePath'], question, null)
    const questionPictureRatio = get_nested(['questionPicture', 'ratio'], question, null)

    // BUILD
    return (
        <CorrectionMobileContentLayout>
            <CorrectionMobileHeader question={question}
                                    questionIndex={questionIndex}
                                    questionCorrectness={questionCorrectness} />
            <SystemQuestionCardQueryText query={questionQuery} />
            {/*<QuestionsMobileQuestionImageLayout>*/}
            {/*    <SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.PORTRAIT}*/}
            {/*                                      isForMobile={true} />*/}
            {/*</QuestionsMobileQuestionImageLayout>*/}
            {!isNullOrUndefined(questionPictureUrl)
                ? <QuestionsMobileQuestionImageLayout>
                    <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                           pictureRatio={questionPictureRatio}
                                           isForMobile={true} />
                  </QuestionsMobileQuestionImageLayout>
                : null}
            <SystemQuestionKnowledge knowledge={questionKnowledge} />
            <SystemOptionsCard question={question}
                               extraClass={"grow rounded-t-[8px] rounded-b-[0px] min-h-[0px] mt-24"}/>
            {!isNullOrUndefined(handleClose)
                ? <CorrectionMobileBottomBar goBackToResults={() => handleClose()} />
                : null }
        </CorrectionMobileContentLayout>
    );
}

// EXPORT
export default CorrectionMobileContent