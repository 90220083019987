
// Used through the app to provide constant texts across components

// PROPERTIES
// ---- System
export const kSystemTexts = {
    loremIpsumExtraShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    loremIpsumShort: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    loremIpsumLong: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    contactMailAddress: "contact@ummia.fr",
    legalDocumentsLastUpdate: "01/09/24",
    understood: "J'ai compris",
    explanation: "Explication",
    trap: "À retenir",
    themes: "Thèmes",
    skills: "Savoir-faire",
    goodSelectedAnswer: "Tu as trouvé la bonne réponse",
    goodUnselectedAnswer: "Voici la bonne réponse",
    falseSelectedAnswer: "Tu as fait une erreur",
    falseUnselectedAnswer: "C'est une erreur",
}

// ---- Errors
export const kErrorTexts = {
    // Grade step screen
    fetchFlashGradeTitle: "Une erreur est survenue pendant le chargement des niveaux",
    fetchFlashGradeSubtitle: "Nous sommes sincèrement désolés et nous faisons notre possible pour résoudre le problème au plus vite",
    // Theme step screen
    fetchFlashThemeTitle: "Une erreur est survenue pendant le chargement des thèmes",
    fetchFlashThemeSubtitle: "Nous sommes sincèrement désolés et nous faisons notre possible pour résoudre le problème au plus vite",
    // Parameter step screen
    fetchFlashSubthemeTitle: "Une erreur est survenue pendant le chargement des niveaux",
    fetchFlashSubthemeSubtitle: "Nous sommes sincèrement désolés et nous faisons notre possible pour résoudre le problème au plus vite",
    fetchQuestionsTitle: "Une erreur est survenue pendant le chargement des questions",
    fetchQuestionsSubtitle: "Nous sommes sincèrement désolés et nous faisons notre possible pour résoudre le problème au plus vite",
    // Questions step screen
    fetchFlashQuestionsTitle: "Une erreur est survenue pendant le chargement des questions",
    fetchFlashQuestionsSubtitle: "Nous faisons notre possible pour résoudre le problème au plus vite",
    // PreviewQuestion screen
    fetchQuestionTitle: "La question demandée n'est pas trouvable",
    fetchQuestionSubtitle: "Veuillez contacter un administrateur",
}

export const kVoidTexts = {
    levelAsList: "Sélectionne un thème pour afficher les savoir-faire associés"
}

// ---- Flash screens
export const kFlashTexts = {
    back: "Retour",
    backToHome: "Retour à l'accueil",
    backToResults: "Retour aux résultats",
    cycleExplanation: "Tu ne le sais peut-être pas mais en classe, on te demandera toujours de maitriser ce que tu appris dans les classes précédentes du même cycle.",
    dontKnowTitle: "Tu ne connais pas la réponse ?",
    dontKnowSubtitle: "Il est possible que tu n'ais pas vu la notion en classe, cela nous aidera à mieux répertorier les questions",
    perfectScore: "Félicitations pour ce score parfait. Tu sembles prêt-e pour l'évaluation",
    veryGoodScore: "C'est très bien, essaye de recommencer pour viser le score parfait",
    goodScore: "C'est bien, continue comme ça. Essaye de relancer un flash pour t'assurer que tu maitrises ce savoir-faire",
    averageScore: "Tu as obtenu la moyenne. Essaye de comprendre tes erreurs et la connaissance associée pour vraiment maitriser ce savoir-faire",
    underScore: "C'est en dessous de la moyenne. En allant dans la correction, tu as toutes les armes pour comprendre tes erreurs et progresser. Courage",
    nullScore: "Garde confiance en toi. En allant dans la correction, tu as toutes les armes pour comprendre la connaissance et comment l'appliquer",
    appreciationError: "Nous avons rencontré une erreur avec ton score. Continue à pratiquer pour progresser",
    leaveCurrentFlashTitle: "Es-tu certain.e de vouloir quitter la série en cours ?",
    leaveCurrentFlashSubtitle: "Tu perdras toute ta progression et tu ne pourras pas consulter la correction des questions auxquelles tu as déjà répondues",
    leaveFlash: "Quitter le flash",
    parameterScreenTitle: "Ça va bientôt commencer !",
    parameterScreenSubtitle: "Nous te conseillons de préparer un crayon et un brouillon",
    gradeScreenTitle: "Paramétrage (1/2)",
    gradeScreenSubtitle: "Tu souhaites travailler une notion de :",
    subthemeScreenTitle: "Paramétrage (2/2)",
    subthemeScreenSubtitle: "Choisis un sous-thème et des savoir-faire :",
    resultsTopTitle: "Félicitations, tu as fini la série !",
    resultsTopSubtitle: "Tu peux maintenant voir tes résultats et consulter la correction par question",
    rulesAdvice: 'Pour t’aider, n’hésite pas à poser des calculs ou tracer des figures sur papier',
    rulesCorrection: 'Accède, à la fin du flash, à tes résultats et à la correction détaillée de chaque question',
    rulesDesktopTabletScreenTitle: "Mode d'entrainement",
    rulesTitle: "Règles",
}

// ---- Landing screen
export const kLandingTexts = {
    punchline: "Progresse en mathématiques en maitrisant les fondamentaux du CM1 à la 3e",
    subPunchline: "Réponds à des séries de questions flash, adaptées à ton niveau scolaire et abordant tous les thèmes du programme de l’Éducation Nationale",
    callToActionLabel: "C'est parti !",
    problemTitle: "Malgré tous tes efforts tu n'arrives pas à progresser en mathématiques ?",
    problemText: "Tu es au bon endroit. Chez Ummia, nous pensons que tout commence par la maitrise des fondamentaux et pour t'aider nous avons conçu des séries de questions flash !",
    solutionZeroTitle: "Fonctionnement",
    solutionMobileZeroLevel: 'Choisis un niveau',
    solutionDesktopTabletZeroLevel: 'Choisis un niveau',
    solutionMobileZeroSkill: "Choisis des savoir-faire",
    solutionDesktopTabletZeroSkill: 'Choisis des savoir-faire',
    solutionMobileZeroAnswer: 'Réponds aux questions',
    solutionDesktopTabletZeroAnswer: 'Réponds aux questions',
    solutionMobileZeroCorrection: 'Consulte la correction détaillée',
    solutionDesktopTabletZeroCorrection: 'Consulte la correction',
    solutionOneTitle: "Flash pour rapide",
    solutionOneText: "Chaque questions est courte et se concentre sur une définition, une règle de calcul ou une propriété. Nous sommes convaincus qu'il faut répéter pour maitriser.",
    solutionTwoTitle: "Comprend tes erreurs pour ne plus les refaire",
    solutionTwoText: "La question est accompagnée de sa correction accessible à la fin de la série. On t'explique chaque proposition tout en te rappelant les connaissances à maitriser.",
    finalCallTitle: "À toi de jouer !",
    finalCallText: "En lançant régulièrement des séries de questions flash, tu pourras mémoriser les bases en mathématiques et vraiment progresser",
}

// ---- Legals screen
export const kLegalTexts = {
    title: 'Mentions légales',
    infoOne: 'Ce site internet est édité par Laboudigue Geoffroy et Quentin',
    infoTwo: 'Adresse : 35 rue raspail, 38000 Grenoble',
    infoThree: 'Téléphone : 09.72.99.87.19',
    infoFour: 'Email : contact@ummia.fr',
    publisherOne: "Laboudigue Quentin",
    publisherTwo: "contact@ummia.fr",
    publisherThree: "09.72.99.87.19",
    hostOne: "Ce site est hébergé par OVH.com",
    hostTwo: "Adresse : 2 rue Kellermann – BP 80157 59053 ROUBAIX CEDEX 1",
    hostThree: "Téléphone : 1007",
    hostFour: "Contact : https://www.ovhcloud.com/fr/contact/",
    intellectualProperty: "Tous les éléments présents sur ce site, y compris les textes, les images, les vidéos, les logos, les marques, ainsi que la mise en page et la structure générale, sont protégés par les lois sur la propriété intellectuelle. Toute reproduction, distribution, modification ou utilisation de ces éléments sans autorisation préalable de Quentin Laboudigue est strictement interdite.",
    personalData: "Les données personnelles collectées sur ce site sont soumises à notre politique de confidentialité, accessible à l'adresse suivante : ",
    privacyLink: " ummia.fr/confidentialite",
    cookie: "Ce site utilise des cookies. Vous pouvez en savoir plus sur notre utilisation des cookies en consultant notre politique de cookies, accessible à l'adresse suivante : ",
    responsibilityOne: "Le contenu de ce site internet est fourni à titre informatif uniquement. L'éditeur ne garantit ni l'exactitude, ni l'exhaustivité des informations présentées sur ce site. L'utilisation des informations et du contenu de ce site se fait à vos propres risques.",
    responsibilityTwo: "L'éditeur ne peut être tenu responsable des éventuelles erreurs, omissions ou inexactitudes présentes sur ce site. Il décline également toute responsabilité en cas de dommages directs ou indirects résultant de l'utilisation de ce site, y compris, mais sans s'y limiter, les pertes de données, les perturbations de service, les préjudices financiers ou les dommages causés par des virus informatiques.",
    responsibilityThree: "Ce site internet peut contenir des liens vers des sites tiers sur lesquels l'éditeur n'a aucun contrôle. L'éditeur ne peut donc pas être tenu responsable du contenu, de la sécurité ou de la confidentialité de ces sites tiers. L'utilisation de ces liens se fait à votre propre discrétion.",
    responsibilityFour: "Il est fortement recommandé de vérifier l'exactitude des informations présentées sur ce site et de consulter un professionnel ou un conseiller qualifié pour obtenir des conseils spécifiques à votre situation.",
    responsibilityFive: "En utilisant ce site, vous acceptez cette décharge de responsabilité. Si vous n'êtes pas d'accord avec ces termes, veuillez quitter le site immédiatement.",
    law: "Les présentes mentions légales sont régies par la loi en vigueur en France. En cas de litige, les tribunaux de Grenoble seront compétents.",
    contact: "Pour toute question ou demande concernant ces mentions légales, veuillez me contacter à l'adresse suivante : "
}

// ---- Privacy screen
export const kPrivacyTexts = {
    title: 'Politique de confidentialité',
    intro: "Bienvenue sur Ummia. Cette politique de confidentialité a pour but de vous informer sur la manière dont nous collectons, utilisons et protégeons les informations personnelles que vous pourriez nous fournir en visitant notre site. Nous prenons la protection de vos données personnelles très au sérieux et nous nous engageons à les traiter de manière confidentielle.",
    collectedDataOne: "Lors de votre visite sur notre site, nous pouvons collecter les types de données personnelles suivants :",
    collectedDataTwo: "Adresse IP : Nous pouvons collecter votre adresse IP à des fins de sécurité et de gestion de site.",
    collectedDataThree: "Cookies : Nous utilisons des cookies pour améliorer votre expérience de navigation sur notre site. Vous pouvez en savoir plus sur notre utilisation des cookies dans notre politique de cookies.",
    collectedDataFour: "Nous collectons et stockons des données de navigation dans le “local storage” de votre navigateur web à des fins de personnalisation de l'expérience utilisateur et pour conserver vos préférences. Ces données peuvent, par exemple, inclure votre avancement sur un “flash”. Ces données sont conservées dans le local storage de votre navigateur aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées. Vous pouvez à tout moment supprimer ces données stockées en vidant les caches de votre navigateur.",
    dataUsage: "Nous utilisons les données collectées uniquement à des fins de gestion du site, d'analyse de l'utilisation et d'amélioration de l'expérience utilisateur. Nous ne vendons, ne louons ni ne partageons vos données personnelles avec des tiers à des fins commerciales.",
    dataSafety: "Nous mettons en place des mesures de sécurité appropriées pour protéger vos données personnelles contre la perte, l'accès non autorisé, la divulgation, la modification ou la destruction. Cependant, veuillez noter qu'aucune transmission de données sur Internet n'est totalement sécurisée, et nous ne pouvons garantir la sécurité absolue de vos données.",
    externalSites: "Notre site peut contenir des liens vers des sites web tiers. Nous ne sommes pas responsables de la politique de confidentialité ou des pratiques de ces sites tiers. Nous vous encourageons à consulter les politiques de confidentialité de ces sites avant de fournir des données personnelles.",
    documentUpdate: "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera affichée sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette politique pour rester informé des modifications éventuelles.",
    contact: "Si vous avez des questions ou des préoccupations concernant cette politique de confidentialité, veuillez nous contacter à l'adresse suivante : "
}

// ---- About screen
// ---- ----- Mission sub-screen
export const kAboutMissionTexts = {
    intro: "De nombreuses études françaises et internationales montrent que le niveau baisse en mathématiques. Il semblerait que les élèves soient lents en calcul, manquent d’intuition et de réflexes. Pourtant, cette discipline n’a jamais été autant présente et indispensable dans notre société (intelligence artificielle, big data, télécommunications).",
    valuesOne: "C’est avec la conviction que ",
    valuesTwo: "chaque enfant peut et doit réussir en mathématiques ",
    valuesThree: "que nous avons développé Ummia. À terme, nous souhaitons devenir le compagnon indispensable d’un élève dans son apprentissage des mathématiques au collège.",
    mission: "À travers le contenu proposé dans l’application, nous souhaitons que chaque élève :",
    missionOne: "Comprenne les liens entre les notions du programme.",
    missionTwo: "Perçoive l’erreur comme une occasion d’apprendre.",
    missionThree: "Devienne autonome et performant dans son travail personnel.",
    outroOne: "Le premier outil que nous avons conçu à ce jour, ",
    outroTwo: "les questions flash",
    outroThree: ", doit permettre aux élèves de renforcer et de consolider leurs fondamentaux.",
}

// ---- ---- Method sub-screen
export const kAboutMethodTexts = {
    title: "Les questions flash, la clé pour consolider ses bases en mathématiques",
    intro: "En tant qu’enseignant, nous avons remarqué que de nombreux élèves en difficulté, malgré leurs efforts, ne parviennent pas à améliorer significativement leurs résultats. Cela peut entraîner un sentiment de découragement puis d’abandon vis-à-vis de la matière. Notre équipe a identifié deux causes majeures pouvant expliquer ce phénomène :",
    problemOneTitle: "Les savoirs fondamentaux ne sont pas maitrisés",
    problemOne: "Il y a la croyance que connaitre sa leçon suffit à maitriser les applications directes de ses connaissances. Par exemple, connaitre la définition de la médiatrice ne garantit pas de savoir la tracer.",
    problemTwoTitle: "Les lacunes s’accumulent dans le temps",
    problemTwo: "L’enseignement des mathématiques repose sur les connaissances acquises les années précédentes. De légères lacunes sur un chapitre peuvent handicaper un élève sur la suite de sa progression.",
    solutionIntroOne: "Pour répondre à ces deux problématiques et rétablir une spirale de la réussite, nous avons conçu, chez Ummia, des séries de ",
    solutionIntroTwo: "questions flash :",
    solutionOneTitle: "Se focaliser sur les fondamentaux",
    solutionOne: "Chacune de nos questions est concise et met en lumière une connaissance fondamentale, comme une définition, une règle de calcul ou encore des propriétés.",
    solutionTwoTitle: "Travailler le passé, pour consolider le futur",
    solutionTwo: "Chaque série de question flash contient des questions de la classe actuelle de l'élève et des classes inférieures du même cycle. Officiellement, le CM1, le CM2 et la 6e forment le cycle 3 et la 5e, 4e, et 3e, le cycle 4.",
    solutionThreeTitle: "Répéter pour maitriser",
    solutionThree: "Pour transformer les bases en automatismes et aborder sereinement les contenus plus complexes en classe, nous recommandons de faire au moins deux séries de questions flash par jour.",
    solutionFourTitle: "Tomber mais avoir les moyens de se relever",
    solutionFour: "Chaque question est accompagnée de sa correction accessible à la fin du QCM, et chaque erreur est expliquée permettant ainsi à l'élève de s’auto-évaluer et d’immédiatement acquérir de nouveaux savoirs.",
}

// ---- ---- Team sub-screen
export const kAboutTeamTexts = {
    title: "Des enseignants passionnés de pédagogie pour accompagner vos enfants",
    intro: "Chez Ummia, nous sommes tous des professeurs de mathématiques certifiés de l’Éducation Nationale en activité ou l’ayant été. Nos compétences complémentaires nous permettent de vous proposer cet outil en ligne qui, on l’espère, vous accompagnera dans votre apprentissage des mathématiques le plus longtemps possible. Si vous souhaitez en savoir plus sur nos parcours, c’est en bas que ça se passe.",
    oneName: "Geoffroy Laboudigue",
    one: "Professeur de mathématiques chevronné avec plus de 10 ans d'expérience, c’est lui qui est à l’initiative d’Ummia. Il est également formateur d’enseignants à l’Éducation Nationale et auteur de manuels scolaires aux éditions Hachette. Il tient depuis ses débuts, la chaine youtube mathlab qui aide de nombreux élèves de France à progresser en mathématiques.",
    twoName: "Claire Ferragut",
    two: "Professeure de mathématiques depuis 15 ans. Elle a eu l’occasion d’enseigner dans de nombreux établissements, de la 6e à la terminale. Son expertise est précieuse pour développer le contenu de l’application qui prépare les élèves aux défis du lycée.",
    threeName: "Lucile Mandin",
    three: "Professeure de mathématiques depuis 8 ans, et également formatrice d’enseignants pour l’Éducation Nationale. Elle a su mettre à profit son sens de la pédagogie dans le cadre de projets innovants centrés autour des mathématiques. Lucile participe activement à l’écriture de scénarios de vidéos pour la chaîne Youtube ainsi que pour les ressources numériques des manuels scolaires aux éditions Hachette.",
    fourName: "Quentin Laboudigue",
    four: "Ingénieur de formation, il est très vite conquis par les problématiques d’apprentissage et de pédagogie. Il se lance alors dans deux années en tant que professeur de mathématiques au collège. Il décide ensuite de se consacrer à ses passions pour le graphisme et la technique pour devenir développeur d’applications web et mobile.",
}

// ---- ---- Contact sub-screen
export const kAboutContactTexts = {
    introOne: "Nous ne sommes actuellement pas assez nombreux pour répondre à des questions concernant le contenu de l'application mais nous restons disponibles pour des ",
    introTwo: "questions légales ",
    introThree: "à l'adresse suivante : ",
    mailAddress: "contact@ummia.fr"
}

