import ResultsScoreCardContentLayout from "../../layouts/resultsStepScreen/ResultsScoreCardContentLayout";
import ResultsSummaryDivider from "../../dividers/resultsStepScreen/ResultsSummaryDivider";
import ResultsDetailedScores from "../../others/resultsStepScreen/ResultsDetailedScores";
import ResultsScore from "../../others/resultsStepScreen/ResultsScore";

function ResultsScoreCard({scores, total}) {
    // Used in ResultsTabletSummary to display flash scores
    // in desktop mode

    // BUILD
    return (
        <div className={"hidden h-[168px] min-h-[168px] w-[384px] min-w-[384px] sm:flex sm:flex-col bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px]"}>
            <ResultsScoreCardContentLayout>
                <ResultsScore correctAnswers={scores[0]} total={total}/>
                <ResultsSummaryDivider />
                <ResultsDetailedScores good={scores[0]}
                                       bad={scores[1]}
                                       neutral={scores[2]} />
            </ResultsScoreCardContentLayout>
        </div>
    );
}

// EXPORT
export default ResultsScoreCard