import LandingMobileMasterTitleText from "../../texts/landingScreen/LandingMobileMasterTitleText";
import LandingMobileMasterSubtitleText from "../../texts/landingScreen/LandingMobileMasterSubtitleText";
import LandingMobileMasterButton from "../../buttons/landingScreen/LandingMobileMasterButton";

function LandingMobileFinalCallToAction() {
    // Used in LandingScreen to display last call to action for users
    // in mobile mode

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 py-48 px-24"}>
            <LandingMobileMasterTitleText title={"À toi de jouer !"} />
            <LandingMobileMasterSubtitleText subtitle={"En lançant régulièrement des séries de questions flash, tu pourras mémoriser les bases en mathématiques et vraiment progresser"} />
            <LandingMobileMasterButton />
        </div>
    );
}

// EXPORT
export default LandingMobileFinalCallToAction