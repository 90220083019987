import ResultsPieChart from "../../charts/resultsStepScreen/ResultsPieChart";

function ResultsGraphList({scores}) {
    // Used in ResultsStepScreen to show list of graphs

    // BUILD
    return (
        <div className={"grow w-full overflow-auto px-16 py-32 flex flex-col space-y-32 justify-start"}>
            <ResultsPieChart scores={scores} />
            {/*<ResultsBarChart questionsByTheme={questionsByTheme} scoresByTheme={scoresByThemes} />*/}
        </div>
    );
}

// EXPORT
export default ResultsGraphList