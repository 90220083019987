import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileSectionTitleText({text}) {
    // Used in

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line text-start"}>
            <span className={"text-[18px] font-bold text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LandingMobileSectionTitleText