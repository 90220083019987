import {isNullOrUndefined} from "../../../services/Validator";

function AboutMobileSectionLayout({children}) {
    // Used in about screens to frame section content

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full flex flex-col justify-start items-center space-y-24 px-24 py-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default AboutMobileSectionLayout