import {routes} from "../../../config/routes";
import LandingTabletLowerFooterItem from "../../items/landingScreen/LandingTabletLowerFooterItem";

function LandingTabletLowerFooter() {
    // Used in LandingMobileFooter to display side content
    // navigation links

    // BUILD
    return (
        <div className={"w-full flex justify-between space-x-12"}>
            <LandingTabletLowerFooterItem label={"À propos"}
                                          route={routes.about} />
            <LandingTabletLowerFooterItem label={"Mentions légales"}
                                          route={routes.legals} />
            <LandingTabletLowerFooterItem label={"Politique de confidentialité"}
                                          route={routes.privacy} />
        </div>
    );
}

// EXPORT
export default LandingTabletLowerFooter