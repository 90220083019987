
// Used through the app to provide routes
// to navigate to

// APP ROUTES
export const routes = {
    landing: "/",
    flash: "/flash",
    about: "/a-propos",
    legals: "/mentions-legales",
    privacy: "/confidentialite",
    preview: "/preview/:id"
}