import {isNullOrUndefined} from "../services/Validator";

// Used through the app to handle Grade picker component controllers

// PROPERTIES
export const GradePickerSize = {
    XSMALL: "xSmall",
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
    XLARGE: "xlarge",
    XXLARGE: "xxlarge",
    XXXLARGE: "xxxlarge"
}

// ---- Level card state
const GradePickerState = {
    NEUTRAL: "Neutral",
    SELECTED: "Selected",
    SUBSELECTED: "Subselected",
}

// ---- Level picker list
const gradePickerList = [
    {
        rank: 7,
        neutral: '/images/levels/levelSeven.png',
        hover: '/images/levels/levelSevenHover.png',
        select: '/images/levels/levelSevenSelect.png',
        subselect: '/images/levels/levelSevenSubselect.png'
    },
    {
        rank: 8,
        neutral: '/images/levels/levelEight.png',
        hover: '/images/levels/levelEightHover.png',
        select: '/images/levels/levelEightSelect.png',
        subselect: '/images/levels/levelEightSubselect.png'
    },
    {
        rank: 9,
        neutral: '/images/levels/levelNine.png',
        hover: '/images/levels/levelNineHover.png',
        select: '/images/levels/levelNineSelect.png',
        subselect: '/images/levels/levelNineSubselect.png'
    },
    {
        rank: 10,
        neutral: '/images/levels/levelTen.png',
        hover: '/images/levels/levelTenHover.png',
        select: '/images/levels/levelTenSelect.png',
        subselect: '/images/levels/levelTenSubselect.png'
    },
    {
        rank: 11,
        neutral: '/images/levels/levelEleven.png',
        hover: '/images/levels/levelElevenHover.png',
        select: '/images/levels/levelElevenSelect.png',
        subselect: '/images/levels/levelElevenSubselect.png'
    },
    {
        rank: 12,
        neutral: '/images/levels/levelTwelve.png',
        hover: '/images/levels/levelTwelveHover.png',
        select: '/images/levels/levelTwelveSelect.png',
        subselect: '/images/levels/levelTwelveSubselect.png'
    },
]

// METHODS
// ---- Render Grade image
export function computeGradePickerImage(grade, selectedGrade, isHovered) {
    // Prepare
    let gradePickerState = GradePickerState.NEUTRAL
    if (!isNullOrUndefined(grade?.id) && !isNullOrUndefined(selectedGrade?.id)) {
        if (grade.id === selectedGrade.id)
            gradePickerState = GradePickerState.SELECTED
    }
    // Find current level in levelPickerList
    let currentLevel = gradePickerList.find((lvl) => lvl?.rank === grade.rank)
    if (isNullOrUndefined(currentLevel)) {
        return '/images/levels/errorLevel.png'
    }
    // Return
    if (gradePickerState === GradePickerState.SELECTED) {
        return currentLevel.select
    } else if (gradePickerState === GradePickerState.SUBSELECTED) {
        return currentLevel.subselect
    } else {
        return isHovered ? currentLevel.hover : currentLevel.neutral
    }
}

// ---- Render Grade badge size
export function computeGradePickerSize(gradePickerSize) {
    switch(gradePickerSize) {
        case GradePickerSize.XSMALL:
            return "w-[40px] min-w-[40px] max-w-[40px] h-[40px] min-h-[40px] max-h-[40px]"
        case GradePickerSize.SMALL:
            return "w-[48px] min-w-[48px] max-w-[48px] h-[48px] min-h-[48px] max-h-[48px]"
        case GradePickerSize.MEDIUM:
            return "w-[56px] min-w-[56px] max-w-[56px] h-[56px] min-h-[56px] max-h-[56px]"
        case GradePickerSize.LARGE:
            return "w-[64px] min-w-[64px] max-w-[64px] h-[64px] min-h-[64px] max-h-[64px]"
        case GradePickerSize.XLARGE:
            return "w-[72px] min-w-[72px] max-w-[72px] h-[72px] min-h-[72px] max-h-[72px]"
        case GradePickerSize.XXLARGE:
            return "w-[80px] min-w-[80px] max-w-[80px] h-[80px] min-h-[80px] max-h-[80px]"
        case GradePickerSize.XXXLARGE:
            return "w-[88px] min-w-[88px] max-w-[88px] h-[88px] min-h-[88px] max-h-[88px]"
        default:
            return "w-[80px] min-w-[80px] max-w-[80px] h-[80px] min-h-[80px] max-h-[80px]"
    }
}