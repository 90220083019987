

// METHODS
// ---- Render Flash score bear emoji regarding success ratio
export function renderScoreEmoji(successRatio) {
    if (successRatio === 100) {
        return "/images/resultsScreen/emojiBearSuccess.png"
    } else if (successRatio >= 80) {
        return "/images/resultsScreen/emojiBearAlmost.png"
    } else if (successRatio >= 60) {
        return "/images/resultsScreen/emojiBearAverage.png"
    } else if (successRatio >= 40) {
        return "/images/resultsScreen/emojiBearSceptic.png"
    } else if (successRatio >= 20) {
        return "/images/resultsScreen/emojiBearConfused.png"
    } else {
        return "/images/resultsScreen/emojiBearConfused.png"
    }
}

// ---- Render Flash score bear ice emoji regarding success ratio
export function renderScoreIceEmoji(successRatio) {
    if (successRatio === 100) {
        return "/images/SystemIceStar.png"
    } else if (successRatio >= 80) {
        return "/images/SystemIceBlink.png"
    } else if (successRatio >= 60) {
        return "/images/SystemIceNormal.png"
    } else if (successRatio >= 40) {
        return "/images/SystemIceSkeptic.png"
    } else if (successRatio >= 20) {
        return "/images/SystemIceConfused.png"
    } else {
        return "/images/SystemIceConfused.png"
    }
}