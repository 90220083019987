import {forwardRef} from "react";
import LandingDesktopSolutionOne from "./LandingDesktopSolutionOne";
import LandingDesktopSolutionTwo from "./LandingDesktopSolutionTwo";
import LandingDesktopWayItWorks from "./LandingDesktopWayItWorks";

const LandingDesktopSolution = forwardRef(({selectedOption, setSelectedOption}, ref) => {
    // Used in LandingScreen to display solution block in Desktop / Tablet mode

    // BUILD
    return (
        <div ref={ref}
             className={"w-full flex flex-col py-56 space-y-40"}>
            <LandingDesktopWayItWorks />
            <LandingDesktopSolutionOne selectedOption={selectedOption}
                                       setSelectedOption={setSelectedOption} />
            <LandingDesktopSolutionTwo />
        </div>
    );
})

// EXPORT
export default LandingDesktopSolution