import {kButtonStyles} from "../../../constants/styles";
import {routes} from "../../../config/routes";
import {SystemPrimaryCustomButton} from "../SystemCustomButton";
import {kLandingTexts} from "../../../constants/texts";

function LandingMobileMasterButton() {
    // Used in LandingScreen to display call to action
    // in mobile mode

    // BUILD
    return (
        <div className={"flex justify-center"}>
            <SystemPrimaryCustomButton
                label={kLandingTexts.callToActionLabel}
                isDisabled={false}
                isLoading={false}
                isFullWidth={false}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                internalLink={routes.flash} />
        </div>
    );
}

// EXPORT
export default LandingMobileMasterButton