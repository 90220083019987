import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {get_subthemes_with_levelAs_from_grade,} from "../../api/subtheme";
import {isArrayNullUndefinedOrEmpty, isNullOrUndefined} from "../../services/Validator";
import {routes} from "../../config/routes";
import SystemScreenLoader from "../../components/loaders/SystemScreenLoader";
import {get_questions_from_levelAsIds} from "../../api/question";
import ParameterDesktopLayout from "../../components/layouts/parameterStepScreen/ParameterDesktopLayout";
import ParameterMobileLayout from "../../components/layouts/parameterStepScreen/ParameterMobileLayout";
import ParameterTabletLayout from "../../components/layouts/parameterStepScreen/ParameterTabletLayout";
import {kFlashTexts} from "../../constants/texts";
import SystemFlashTopTexts from "../../components/texts/SystemFlashTopTexts";
import {get_flash_grades} from "../../api/grade";
import ParameterScreenError from "../../components/errors/parameterScreen/ParameterScreenError";
import ParameterContent from "../../components/others/parameterStepScreen/ParameterContent";
import ParameterMobileContent from "../../components/others/parameterStepScreen/ParameterMobileContent";
import ParameterTabletBottomBar from "../../components/bars/parameterStepScreen/ParameterTabletBottomBar";
import ParameterDesktopBottomBar from "../../components/bars/parameterStepScreen/ParameterDesktopBottomBar";
import ParameterMobileBottomBar from "../../components/bars/parameterStepScreen/ParameterMobileBottomBar";
import ParameterMobileTopBar from "../../components/bars/parameterStepScreen/ParameterMobileTopBar";
import ParameterTabletTopBar from "../../components/bars/parameterStepScreen/ParameterTabletTopBar";
import ParameterDesktopTopBar from "../../components/bars/parameterStepScreen/ParameterDesktopTopBar";
import ParameterQuestionsScreenError from "../../components/errors/parameterScreen/ParameterQuestionsScreenError";
import {fakerFlashGrades, fakerQuestions, fakerSubthemesWithLevelAs} from "../../services/Faker";

function ParameterStepScreen({selectedGrade, setFlashGrade, fetchQuestionsCallback}) {
    // Used in FlashScreen for the user to select LevelAs through
    // Subthemes

    // PROPERTIES
    let navigate = useNavigate();
    // ---- Grades
    const [isGradesLoading, setIsGradesLoading] = useState(true)
    const [hasGradesError, setHasGradesError] = useState(false)
    const [grades, setGrades] = useState(null)
    // ---- Subthemes
    const [isSubthemesLoading, setIsSubthemesLoading] = useState(false)
    const [hasSubthemesError, setHasSubthemesError] = useState(false)
    const [subthemes, setSubthemes] = useState([])
    const [selectedSubtheme, setSelectedSubtheme] = useState(null)
    const [levelAs, setLevelAs] = useState([])
    const [selectedLevelAs, setSelectedLevelAs] = useState([])
    const [displayMobileLevelAs, setDisplayMobileLevelAs] = useState(false)
    // ---- Questions
    const [isFetchQuestionsLoading, setIsFetchQuestionsLoading] = useState(false)
    const [hasFetchQuestionsError, setHasFetchQuestionsError] = useState(false)

    // HOOKS
    // ---- Triggered at rendering : set Grades
    useEffect(() => {
        getFlashGrades()
    }, [])

    // BUILD
    if (hasGradesError)
        return <ParameterScreenError goBackToHome={() => {navigate(routes.landing)}}
                                     retryFetching={() => getFlashGrades()} />
    if (hasFetchQuestionsError)
        return <ParameterQuestionsScreenError goBackToHome={() => {navigate(routes.landing)}}
                                              retryFetching={() => fetchFlashQuestions()} />
    if (isGradesLoading || isFetchQuestionsLoading)
        return <SystemScreenLoader />
    return (
        <div className="animate-appear overflow-auto bg-backgroundLight3 w-full h-[100svh] md:h-screen flex flex-col items-center justify-start">
            {/** MOBILE */}
            <ParameterMobileLayout>
                <ParameterMobileTopBar goBackToHome={() => navigate(routes.landing)}
                                       selectedGrade={selectedGrade} />
                <ParameterMobileContent grades={grades}
                                        selectedGrade={selectedGrade}
                                        setFlashGrade={getSubthemesWithLevelAsFromGrade}
                                        isSubthemesLoading={isSubthemesLoading}
                                        hasSubthemesError={hasSubthemesError}
                                        subthemes={subthemes}
                                        selectSubtheme={selectSubtheme}
                                        selectedSubtheme={selectedSubtheme}
                                        levelAs={levelAs}
                                        selectedLevelAs={selectedLevelAs}
                                        toggleLevelA={toggleLevelA}
                                        displayMobileLevelAs={displayMobileLevelAs}
                                        goBackToSubthemes={goBackToSubthemes} />
                <ParameterMobileBottomBar selectedLevelAs={selectedLevelAs}
                                          fetchFlashQuestions={fetchFlashQuestions}
                                          isFetchingLoading={isFetchQuestionsLoading}
                                          goToPreviousStep={() => navigate(routes.landing)} />
            </ParameterMobileLayout>
            {/** TABLET */}
            <ParameterTabletTopBar goBackToHome={() => navigate(routes.landing)} />
            <ParameterTabletLayout>
                <SystemFlashTopTexts title={kFlashTexts.parameterScreenTitle}
                                     subtitle={kFlashTexts.parameterScreenSubtitle} />
                <ParameterContent grades={grades}
                                   selectedGrade={selectedGrade}
                                   setFlashGrade={getSubthemesWithLevelAsFromGrade}
                                   isSubthemesLoading={isSubthemesLoading}
                                   hasSubthemesError={hasSubthemesError}
                                   subthemes={subthemes}
                                   selectSubtheme={selectSubtheme}
                                   selectedSubtheme={selectedSubtheme}
                                   levelAs={levelAs}
                                   selectedLevelAs={selectedLevelAs}
                                   toggleLevelA={toggleLevelA} />
                <ParameterTabletBottomBar selectedGrade={selectedGrade}
                                         selectedLevelAs={selectedLevelAs}
                                         fetchFlashQuestions={fetchFlashQuestions}
                                         isFetchingLoading={isFetchQuestionsLoading}
                                         goToPreviousStep={() => navigate(routes.landing)}
                                         extraClass={"mt-24"} />
            </ParameterTabletLayout>
            {/** DESKTOP */}
            <ParameterDesktopTopBar goBackToHome={() => navigate(routes.landing)} />
            <ParameterDesktopLayout>
                <SystemFlashTopTexts title={kFlashTexts.parameterScreenTitle}
                                     subtitle={kFlashTexts.parameterScreenSubtitle} />
                <ParameterContent grades={grades}
                                  selectedGrade={selectedGrade}
                                  setFlashGrade={getSubthemesWithLevelAsFromGrade}
                                  isSubthemesLoading={isSubthemesLoading}
                                  hasSubthemesError={hasSubthemesError}
                                  subthemes={subthemes}
                                  selectSubtheme={selectSubtheme}
                                  selectedSubtheme={selectedSubtheme}
                                  levelAs={levelAs}
                                  selectedLevelAs={selectedLevelAs}
                                  toggleLevelA={toggleLevelA} />
                <ParameterDesktopBottomBar selectedGrade={selectedGrade}
                                           selectedLevelAs={selectedLevelAs}
                                           fetchFlashQuestions={fetchFlashQuestions}
                                           isFetchingLoading={isFetchQuestionsLoading}
                                           goToPreviousStep={() => navigate(routes.landing)}
                                           extraClass={"mt-24"} />
            </ParameterDesktopLayout>
        </div>
    );

    // METHODS
    // ---- ---- ----
    // NAVIGATION
    // ---- ---- ----
    function goBackToSubthemes() {
        setDisplayMobileLevelAs(false)
        setSelectedSubtheme(null)
        setSelectedLevelAs([])
    }

    // ---- ---- ----
    // API CALLS
    // ---- ---- ----
    // ---- Fetch flash levels from the api
    function getFlashGrades() {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_FAKER === "true") {
            setGrades(fakerFlashGrades)
            setIsGradesLoading(false)
            setHasGradesError(false)
            return
        }
        // ---- For .env.faker >>>>

        // Switch loader on and clear error
        setIsGradesLoading(true)
        setHasGradesError(false)
        // Perform
        get_flash_grades()
            .then((res) => {
                setGrades(res)
                setHasGradesError(false)
            }).catch((err) => {
                setHasGradesError(true)
                console.error(err)
        }).finally(() => {
            setIsGradesLoading(false)
        })
    }

    // ---- Fetch Subthemes with LevelAs based on a specific Grade and Theme
    function getSubthemesWithLevelAsFromGrade(selectedGrade) {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_FAKER === "true") {
            setSubthemes(fakerSubthemesWithLevelAs)
            setFlashGrade(selectedGrade)
            setIsSubthemesLoading(false)
            setHasSubthemesError(false)
            return
        }
        // ---- For .env.faker >>>>

        // Check input parameters
        if (isNullOrUndefined(selectedGrade?.id))
            return;
        // Perform setFlashGrade from FlashScreen
        setFlashGrade(selectedGrade)
        // Reset screen state
        resetScreenState()
        // Switch loader on and clear error
        setIsSubthemesLoading(true)
        setHasSubthemesError(false)
        // Perform request
        get_subthemes_with_levelAs_from_grade(selectedGrade?.id)
            .then((res) => {
                setSubthemes(res)
                setHasSubthemesError(false)
            }).catch((err) => {
            setHasSubthemesError(true)
            console.error(err)
        }).finally(() => {
            setIsSubthemesLoading(false)
        })
    }

    // ---- Api call : fetch questions
    function fetchFlashQuestions() {
        // <<<< For .env.faker ----
        if (process.env.REACT_APP_FAKER === "true") {
            fetchQuestionsCallback(fakerQuestions)
            setIsFetchQuestionsLoading(false)
            setHasFetchQuestionsError(false)
            return
        }
        // ---- For .env.faker >>>>
        // If no levelId or already loading, return
        if (isArrayNullUndefinedOrEmpty(selectedLevelAs))
            return
        // Switch loader on and clear error
        setIsFetchQuestionsLoading(true)
        setHasFetchQuestionsError(false)
        // Prepare request body
        let data = {
            levelAsIds: selectedLevelAs.map(levelA => levelA.id)
        }
        // Perform
        get_questions_from_levelAsIds(data)
            .then((res) => {
                // Handle response
                if (isArrayNullUndefinedOrEmpty(res)) {
                    setHasFetchQuestionsError(true)
                } else {
                    fetchQuestionsCallback(res)
                }
            })
            .catch((err) => {
                // Print and switch error on
                console.log(err)
                setHasFetchQuestionsError(true)
            })
            .finally(() => {
                // Switch loader off
                setIsFetchQuestionsLoading(false)
            })
    }

    // ---- ---- ----
    // USER INTERACTION
    // ---- ---- ----
    // ---- Toggle LevelA : add it or delete it from the current selectedLevelAs list
    function toggleLevelA(currentLevelA) {
        if (selectedLevelAs.includes(currentLevelA)) {
            // Case : currentLevelA is already in the selectedLevelAs => delete
            const updatedList = selectedLevelAs.filter(levelA => levelA.id !== currentLevelA.id)
            setSelectedLevelAs(updatedList)
        } else {
            // Case : currentLevelA is not in selectedLevelAs => add
            // ---- Get currentLevelA in levelAs
            const index = levelAs.indexOf(currentLevelA)
            // ---- React rebuild components by changing the reference of the object
            // ---- Rebuild a whole new LevelAs list
            const updatedList = []
            selectedLevelAs.forEach(levelA => {
                updatedList.push(levelA)
            })
            updatedList.splice(index, 0, currentLevelA)
            // ---- Set state
            setSelectedLevelAs(updatedList)
        }
    }

    // ---- Select a specific subtheme
    function selectSubtheme(subtheme, isForMobile) {
        // Check input parameter
        if (isNullOrUndefined(subtheme))
            return
        // Set current selected Subtheme
        setSelectedSubtheme(subtheme)
        // Set current displayed LevelAs
        setLevelAs(subtheme["subthemeLevelAs"])
        // Set selectedLevelAs from the current selected Subtheme
        setSelectedLevelAs(subtheme["subthemeLevelAs"])
        // Update view for mobile if necessary
        if (isForMobile)
            setDisplayMobileLevelAs(true)
    }

    // ---- ---- ----
    // OTHERS
    // ---- ---- ----
    // ---- Reset screen global state
    function resetScreenState() {
        setSubthemes([])
        setSelectedSubtheme(null)
        setLevelAs([])
        setSelectedLevelAs([])
        setDisplayMobileLevelAs(false)
    }

}

// EXPORT
export default ParameterStepScreen