import {kFlashTexts} from "../constants/texts";
import {isNullOrUndefined} from "../services/Validator";

// METHODS
// ---- Compute flash appreciation
export function computeFlashAppreciation(successRatio) {
    if (isNullOrUndefined(successRatio) || typeof successRatio !== 'number' || successRatio > 100 || successRatio < 0) {
        return kFlashTexts.appreciationError
    } else {
        if (successRatio === 100) {
            return kFlashTexts.perfectScore
        } else if (successRatio >= 80) {
            return kFlashTexts.veryGoodScore
        } else if (successRatio >= 60) {
            return kFlashTexts.goodScore
        } else if (successRatio >= 50) {
            return kFlashTexts.averageScore
        } else if (successRatio >= 30) {
            return kFlashTexts.underScore
        } else {
            return kFlashTexts.nullScore
        }
    }
}