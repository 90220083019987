import {isNullOrUndefined} from "../../../services/Validator";

function ResultsScoreFractionText({correctAnswers, total, toggleShowPercentage}) {
    // Used in ResultsTotalScore to display flash results using a
    // fraction

    // BUILD
    if (isNullOrUndefined(correctAnswers) || isNullOrUndefined(total))
        return null;
    return (
        <div className="flex space-x-6 items-center whitespace-nowrap cursor-pointer text-[40px] font-medium"
             onClick={() => toggleShowPercentage()}>
            <span className="text-white">{correctAnswers}</span>
            <span className="text-greyLight7 pb-4 text-[32px]">/</span>
            <span className="text-greyLight7">{total}</span>
        </div>
    );
}

// EXPORT
export default ResultsScoreFractionText