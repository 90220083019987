import SystemCollapsableMenuItem from "../../items/SystemCollapsableMenuItem";
import {routes} from "../../../config/routes";
import SystemCollapsableMenuLayout from "../../layouts/SystemCollapsableMenuLayout";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import SystemMobileTopBarLayout from "../../layouts/SystemMobileTopBarLayout";
import SystemMobileBurgerMenuIconButton from "../../buttons/SystemMobileBurgerMenuIconButton";

function LandingMobileTopBar({scrollToSolution}) {
    // Used in LandingScreen to display top bar in mobile mode

    // PROPERTIES
    let navigate = useNavigate()
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    // BUILD
    return (
        <div className={"w-full flex flex-col sm:hidden bg-backgroundLight1"}>
            <SystemMobileTopBarLayout>
                <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.XSMALL} />
                <SystemMobileBurgerMenuIconButton onPressed={() => setIsMenuOpen(!isMenuOpen)} />
            </SystemMobileTopBarLayout>
            <SystemCollapsableMenuLayout isOpen={isMenuOpen} >
                <SystemCustomDivider color={"bg-greyLight1"} />
                <SystemCollapsableMenuItem label={"À propos d'Ummia"}
                                           icon={"favorite"}
                                           onPress={() => navigate(routes.about)} />
                <SystemCustomDivider color={"bg-greyLight1"} />
                <SystemCollapsableMenuItem label={"Fonctionnement"}
                                           icon={"settings"}
                                           onPress={() => scrollToSolution()} />
                <SystemCustomDivider color={"bg-greyLight1"} />
                <SystemCollapsableMenuItem label={"Lancer un flash !"}
                                           icon={"bolt"}
                                           onPress={() => navigate(routes.flash)}
                                           isMasterAction={true} />
                <SystemCustomDivider color={"bg-greyLight1"} />
            </SystemCollapsableMenuLayout>
        </div>
    );
}

// EXPORT
export default LandingMobileTopBar