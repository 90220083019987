import SystemFullScreenCenteredLayout from "../../layouts/SystemFullScreenCenteredLayout";
import SystemScreenErrorLayout from "../../layouts/SystemScreenErrorLayout";
import SystemScreenErrorContent from "../../others/SystemScreenErrorContent";
import SystemScreenErrorActionButtons from "../../buttons/SystemScreenErrorActionButtons";
import {kErrorTexts} from "../../../constants/texts";

function ParameterScreenError({goBackToHome, retryFetching}) {
    // Used in ParameterStepScreen to display error when
    // fetching flash Subthemes from the api failed

    // BUILD
    return (
        <SystemFullScreenCenteredLayout>
            <SystemScreenErrorLayout>
                <SystemScreenErrorContent title={kErrorTexts.fetchFlashSubthemeTitle}
                                          subtitle={kErrorTexts.fetchFlashSubthemeSubtitle} />
                <SystemScreenErrorActionButtons onSecondaryPressed={goBackToHome}
                                                secondaryLabel={"Retour"}
                                                onPrimaryPressed={retryFetching}
                                                primaryLabel={"Réessayer"} />

            </SystemScreenErrorLayout>
        </SystemFullScreenCenteredLayout>
    );
}

// EXPORT
export default ParameterScreenError