import QuestionsMobileCardHeader from "../questionsStepScreen/QuestionsMobileCardHeader";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";

function CorrectionMobileHeader({question, questionIndex, questionCorrectness}) {
    // Used in CorrectionMobileContent to display header with
    // Question meta info

    // BUILD
    return (
        <div className={"w-full flex flex-col"}>
            <QuestionsMobileCardHeader question={question}
                                       questionIndex={questionIndex}
                                       questionCorrectness={questionCorrectness} />
            <SystemCustomDivider color={'bg-greyLight5'}
                                 extraClass={"min-h-[1px]"} />
        </div>
    );
}

// EXPORT
export default CorrectionMobileHeader