import LandingDesktopTabletTopBarActions from "../../others/landingScreen/LandingDesktopTabletTopBarActions";
import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemDesktopTopBarLayout from "../../layouts/SystemDesktopTopBarLayout";

function LandingDesktopTopBar ({scrollToSolution}) {
    // Used in LandingScreen to display screen top bar

    // BUILD
    return (
        <SystemDesktopTopBarLayout extraClass={"bg-backgroundLight1"} >
            <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.SMALL} />
            <LandingDesktopTabletTopBarActions scrollToSolution={scrollToSolution} />
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default LandingDesktopTopBar