
function SystemMobileFlashLogoImage({extraClass}) {
    // Used through the app to display flash logo
    // in mobile mode

    // BUILD
    return (
        <div className={"animate-appear h-[24px] min-h-[24px] min-w-[12px] flex justify-center items-center " + extraClass}>
            <img className={"object-contain h-full"}
                 src={'/images/system/flashLogo.png'}
                 alt="Flash" />
        </div>
    );
}

// EXPORT
export default SystemMobileFlashLogoImage