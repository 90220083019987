import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileStepItemIcon({icon}) {
    // Used in LandingMobileStepItem to display step icon

    // BUILD
    if (isNullOrUndefined(icon))
        return null;
    return (
        <div className={"h-[48px] min-h-[48px] w-[48px] min-w-[48px] rounded-full border-[1px] border-primaryLight5 leading-none flex items-center justify-center"}>
            <i className="material-icons text-[32px] text-primaryLight5">{icon}</i>
        </div>
    );
}

// EXPORT
export default LandingMobileStepItemIcon