import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionTabletLayout({children}) {
    // Used in CorrectionStepScreen to frame content
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto relative w-full grow bg-backgroundLight3 hidden sm:flex sm:flex-col lg:hidden items-center justify-start rounded-t-[32px]"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionTabletLayout