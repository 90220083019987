

// METHODS
// ---- Test if current option is selected option
function isOptionSelected(option, selectedOption) {
    if (option !== null && selectedOption !== null) {
        return option.proposal === selectedOption.proposal
    } else {
        return false
    }
}

// ---- Render question option style
export function renderQuestionOptionStyle(option, selectedOption, isHovered) {
    if (isOptionSelected(option, selectedOption)) {
        return "border-primaryLight1 text-white"
    } else {
        return isHovered ? "border-greyLight7 text-greyLight11" : "border-greyLight5 text-greyLight9"
    }
}