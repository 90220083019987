import {useState} from "react";
import {renderAboutBarTextColor} from "../../controllers/AboutTabBarController";
import {isNullOrUndefined} from "../../services/Validator";

function SystemMobileTabBarItem({item, isSelected, setSelectedTab}) {
    // Used in AboutMobileTabBar to display one tab bar item

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    if (isNullOrUndefined(item))
        return null;
    return (
        <div className={`cursor-pointer w-full flex items-center justify-center ${isSelected ? "border-b border-greyLight11" : ""}`}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}
             onClick={setSelectedTab}>
            <div className={"flex items-center justify-center w-[40px] h-[40px]"}>
                <i className={`material-icons ${renderAboutBarTextColor(isSelected, isHovered)} text-[24px] font-normal`}>
                    {item.icon ?? 'adjust'}
                </i>
            </div>
        </div>
    );
}

// EXPORT
export default SystemMobileTabBarItem