

function LandingTabletUpperFooter() {
    // Used in LandingMobileFooter to display disclaimer

    // BUILD
    return (
        <div className={"flex items-center justify-between"}>
            <span className={"text-[16px] text-greyLight5 font-medium"}>
                {"Ummia © tous droits réservés"}
            </span>
        </div>
    );
}

// EXPORT
export default LandingTabletUpperFooter