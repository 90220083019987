import {isNullOrUndefined} from "../../../services/Validator";

function ResultsQuestionIndex({number, correctness}) {
    // Used in ResultsQuestionItem to display question
    // number and correctness via its color

    // BUILD
    if (isNullOrUndefined(number))
        return null;
    return (
        <div className={`${_renderColor(correctness)} font-semibold text-[18px] w-[40px] min-w-[40px] h-[32px] min-h-[32px] flex items-center justify-start`}>
            <span>{"#" + number}</span>
        </div>
    );

    // METHODS
    // ---- Render question index number color regarding answer correctness
    function _renderColor(correctness) {
        switch(correctness) {
            case true:
                return "text-primary"
            case false:
                return "text-secondary"
            case null:
                return "text-greyLight7"
            default:
                return "text-greyLight7"
        }
    }
}

// EXPORT
export default ResultsQuestionIndex