import {forwardRef} from "react";
import LandingTabletConfusedImage from "../../images/landingScreen/LandingTabletConfusedImage";
import LandingTabletSectionTitleText from "../../texts/landingScreen/LandingTabletSectionTitleText";
import LandingTabletSectionCoreText from "../../texts/landingScreen/LandingTabletSectionCoreText";
import {kLandingTexts} from "../../../constants/texts";


const LandingTabletProblem = forwardRef((props, ref) => {
    // Used in LandingScreen to display app problem statement
    // in desktop tablet mode

    // BUILD
    return (
        <div ref={ref}
             className={"w-full flex flex-col items-center justify-start py-56 space-y-24"}>
            <LandingTabletConfusedImage />
            <LandingTabletSectionTitleText text={kLandingTexts.problemTitle} />
            <LandingTabletSectionCoreText text={kLandingTexts.problemText} />
        </div>
    );
})

// EXPORT
export default LandingTabletProblem