import SystemFlashTopTexts from "../SystemFlashTopTexts";
import {kFlashTexts} from "../../../constants/texts";

function ResultsDesktopTopTexts() {
    // Used in ResultsStepScreen to display
    // top page titles in desktop mode

    // BUILD
    return (
        <div className={"w-full hidden lg:flex"}>
            <SystemFlashTopTexts title={kFlashTexts.resultsTopTitle}
                                 subtitle={kFlashTexts.resultsTopSubtitle}/>
        </div>
    );
}

// EXPORT
export default ResultsDesktopTopTexts