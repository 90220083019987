import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionDesktopContentLayout({children}) {
    // Used in CorrectionStepScreen to frame content in
    // both desktop and tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-[928px] grow hidden lg:flex lg:flex-col items-center justify-start pb-32 px-24 pt-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionDesktopContentLayout