import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionQuestionIndex({number, correctness}) {
    // Used in SystemQuestionHeader to display Question index
    // in flash

    // BUILD
    if (isNullOrUndefined(number))
        return null;
    return (
        <div className={`${_renderColor(correctness)} leading-none font-semibold text-[14px] sm:text-[16px] flex items-center justify-start`}>
            <span>
                {"#" + number}
            </span>
        </div>
    );

    // METHODS
    // ---- Render question index number color regarding answer correctness
    function _renderColor(correctness) {
        switch(correctness) {
            case true:
                return "text-primaryLight4"
            case false:
                return "text-secondaryLight4"
            case null:
                return "text-greyLight5"
            default:
                return "text-greyLight5"
        }
    }
}

// EXPORT
export default CorrectionQuestionIndex