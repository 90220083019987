import LawContentMobileLayout from "../components/layouts/lawScreens/LawContentMobileLayout";
import LawMobileTitle from "../components/others/lawScreens/LawMobileTitle";
import PrivacyMobileContent from "../components/others/privacyScreen/PrivacyMobileContent";
import SystemDesktopTabletExtraContentTopBar from "../components/bars/SystemDesktopTabletExtraContentTopBar";
import LawDesktopTabletLayout from "../components/layouts/lawScreens/LawDesktopTabletLayout";
import LawContentDesktopTabletContentLayout
    from "../components/layouts/lawScreens/LawContentDesktopTabletContentLayout";
import LawDesktopTabletTitle from "../components/others/lawScreens/LawDesktopTabletTitle";
import PrivacyDesktopTabletContent from "../components/others/privacyScreen/PrivacyDesktopTabletContent";
import LawMobileTopBar from "../components/bars/lawScreens/LawMobileTopBar";
import {kPrivacyTexts, kSystemTexts} from "../constants/texts";


function PrivacyScreen() {
    // Used in index by the router as an entry point
    // for compulsory legal texts

    // BUILD
    return (
        <div className={"overflow-auto w-full h-[100svh] md:h-screen md:min-h-screen flex flex-col items-center justify-start"}>
            {/** MOBILE */}
            <LawMobileTopBar />
            <LawContentMobileLayout>
                <LawMobileTitle pageTitle={kPrivacyTexts.title}
                                lastUpdateDate={kSystemTexts.legalDocumentsLastUpdate}
                                isLegals={true} />
                <PrivacyMobileContent />
            </LawContentMobileLayout>
            {/** DESKTOP + TABLET */}
            <SystemDesktopTabletExtraContentTopBar />
            <LawDesktopTabletLayout>
                <LawContentDesktopTabletContentLayout>
                    <LawDesktopTabletTitle pageTitle={kPrivacyTexts.title}
                                           lastUpdateDate={kSystemTexts.legalDocumentsLastUpdate}
                                           isLegals={true} />
                    <PrivacyDesktopTabletContent />
                </LawContentDesktopTabletContentLayout>
            </LawDesktopTabletLayout>
        </div>
    );
}

// EXPORT
export default PrivacyScreen