import ResultsDetailedScoreItem from "../../items/resultsStepScreen/ResultsDetailedScoreItem";

function ResultsDetailedScores({good, bad, neutral}) {
    // Used in ResultsSummary to display detailed score
    // by answer type list

    // BUILD
    return (
        <div className="grow sm:grow-0 h-full flex flex-col justify-center space-y-8 lg:space-y-12">
            <ResultsDetailedScoreItem isGoodScore={true}
                                      color={"bg-primaryDark1"}
                                      score={good}
                                      label={good > 1 ? "Bonnes réponses" : "Bonne réponse"} />
            <ResultsDetailedScoreItem isGoodScore={false}
                                      color={"bg-secondary"}
                                      score={bad}
                                      label={bad > 1 ? "Erreurs" : "Erreur"} />
            <ResultsDetailedScoreItem isGoodScore={false}
                                      color={"bg-greyLight7"}
                                      score={neutral}
                                      label={"N'a pas su répondre"} />
        </div>
    );
}

// EXPORT
export default ResultsDetailedScores