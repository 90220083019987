import {isNullOrUndefined} from "../../services/Validator";

function SystemFlashTopTexts({title, subtitle, extraClass}) {
    // Used in ResultsStepScreen to display step title and subtitle
    // in Desktop mode

    // BUILD
    if (isNullOrUndefined(title) && isNullOrUndefined(subtitle))
        return null
    return(
        <div className={"hidden w-full sm:flex sm:flex-col items-center " + extraClass}>
            <span className="font-semibold text-white text-[20px] lg:text-[24px] text-center">
                {title}
            </span>
            <span className="font-normal text-greyLight7 text-[18px] lg:text-[20px] text-center">
                {subtitle}
            </span>
        </div>
    );
}

// EXPORT
export default SystemFlashTopTexts