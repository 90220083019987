import LandingDesktopMasterTitleText from "../../texts/landingScreen/LandingDesktopMasterTitleText";
import LandingDesktopMasterSubtitleText from "../../texts/landingScreen/LandingDesktopMasterSubtitleText";
import LandingDesktopMasterButton from "../../buttons/landingScreen/LandingDesktopMasterButton";
import {kLandingTexts} from "../../../constants/texts";

function LandingDesktopFinalCallToAction() {
    // Used in

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 py-56"}>
            <LandingDesktopMasterTitleText title={kLandingTexts.finalCallTitle} />
            <LandingDesktopMasterSubtitleText subtitle={kLandingTexts.finalCallText} />
            <LandingDesktopMasterButton />
        </div>
    );
}

// EXPORT
export default LandingDesktopFinalCallToAction