import SystemFlashLogoImage from "../../images/SystemFlashLogoImage";
import {FlashLogoTextSize} from "../../../controllers/FlashLogoTextController";
import SystemDesktopTabletLeaveFlashButton from "../../buttons/SystemDesktopTabletLeaveFlashButton";
import SystemDesktopTopBarLayout from "../../layouts/SystemDesktopTopBarLayout";
import SystemFlashTopBarLogoLayout from "../../layouts/SystemFlashTopBarLogoLayout";
import SystemFlashSelectedGradeBadge from "../../badges/SystemFlashSelectedGradeBadge";

function ResultsDesktopTopBar({selectedGrade, onLeaveFlashPressed}) {
    // Used in ResultsStepScreen to display top bar in
    // desktop mode

    // BUILD
    return (
        <SystemDesktopTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL}/>
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemDesktopTopBarLayout>
    );
}

// EXPORT
export default ResultsDesktopTopBar