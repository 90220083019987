import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileMasterTitleText({title}) {
    // Used in LandingMobileShowCase to display master
    // title text in mobile mode

    // BUILD
    if(isNullOrUndefined(title))
        return null
    return (
        <div className={"w-full text-center leading-tight"}>
            <span className={"text-white font-bold text-[26px]"}>
               {title}
            </span>
        </div>
    );
}

// EXPORT
export default LandingMobileMasterTitleText