import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionMobileLayout({children}) {
    // Used in CorrectionStepScreen to frame content
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto w-full grow bg-backgroundLight3 flex flex-col sm:hidden items-center justify-start rounded-t-[8px]"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionMobileLayout