import SystemQuestionCardHeaderSubText from "../texts/SystemQuestionCardHeaderSubText";
import SystemCustomDivider from "../dividers/SystemCustomDivider";
import {isNullOrUndefined} from "../../services/Validator";
import CorrectionQuestionIndex from "./correctionStepScreen/CorrectionQuestionIndex";

function SystemQuestionCardHeader({subtheme,
                                   task,
                                   questionIndex,
                                   questionCorrectness, extraClass}) {
    // Used in SystemDraftQuestionCard to display Question details,
    // in all mobile, tablet, and desktop modes

    // BUILD
    if (isNullOrUndefined(subtheme) && isNullOrUndefined(task))
        return null
    return (
        <div className={"w-full flex flex-col space-y-12 " + extraClass}>
            <div className={"flex items-center justify-between"}>
                {/*<SystemQuestionCardHeaderMainText subtheme={subtheme} />*/}
                <CorrectionQuestionIndex number={questionIndex}
                                         correctness={questionCorrectness} />
                <SystemQuestionCardHeaderSubText task={task} />
            </div>
            <SystemCustomDivider color={'bg-greyLight5'} />
        </div>
    );
}

// EXPORT
export default SystemQuestionCardHeader