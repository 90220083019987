import LandingDesktopSectionTitleText from "../../texts/landingScreen/LandingDesktopSectionTitleText";
import LandingDesktopSectionCoreText from "../../texts/landingScreen/LandingDesktopSectionCoreText";
import {kMethodQuestion} from "../../../constants/objects";
import SystemQuestionKnowledge from "../SystemQuestionKnowledge";
import SystemOptionsCard from "../../cards/SystemOptionsCard";
import {kLandingTexts} from "../../../constants/texts";
import {get_nested} from "../../../services/JsonHandler";

function LandingDesktopSolutionTwo() {
    // Used in LandingDesktopSolution to display solution
    // two content in desktop / Tablet mode

    // PROPERTIES
    const questionKnowledge = get_nested(['levelA', 'knowledge', 'definition'], kMethodQuestion, null)

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 items-start justify-start"}>
            <div className={"w-full flex flex-col space-y-12 items-start justify-start"}>
                <div className={"flex justify-start items-center space-x-8"}>
                    <i className="material-icons text-[32px] text-white">{"insights"}</i>
                    <LandingDesktopSectionTitleText text={kLandingTexts.solutionTwoTitle} />
                </div>
                <LandingDesktopSectionCoreText text={kLandingTexts.solutionTwoText} />
            </div>
            <SystemQuestionKnowledge knowledge={questionKnowledge} />
            <SystemOptionsCard question={kMethodQuestion} />
        </div>
    );
}

// EXPORT
export default LandingDesktopSolutionTwo