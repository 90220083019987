import {forwardRef} from "react";
import LandingMobileWayItWorks from "./LandingMobileWayItWorks";
import LandingMobileSolutionOne from "./LandingMobileSolutionOne";
import LandingMobileSolutionTwo from "./LandingMobileSolutionTwo";

const LandingMobileSolution = forwardRef(({selectedOption, setSelectedOption}, ref) => {
    // Used in LandingScreen to display app solutions blocks

    // BUILD
    return (
        <div ref={ref}
             className={"w-full flex flex-col py-48 px-24 space-y-32"}>
            <LandingMobileWayItWorks />
            <LandingMobileSolutionOne selectedOption={selectedOption}
                                      setSelectedOption={setSelectedOption} />
            <LandingMobileSolutionTwo />
        </div>
    );
})

// EXPORT
export default LandingMobileSolution