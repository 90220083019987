import {Collapse} from "react-collapse";

function SystemCollapsableMenuLayout({isOpen, children}) {
    // Used in LandingMobileTopBar to display collapsable menu
    // for further navigation in mobile mode

    // BUILD
    return (
        <Collapse isOpened={isOpen}>
            <div className={'flex flex-col w-full bg-backgroundLight1'}>
                {children}
            </div>
        </Collapse>
    );
}

// EXPORT
export default SystemCollapsableMenuLayout