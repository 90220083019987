
function LandingDesktopTabletStepDivider() {
    // Used in LandingDesktopWayItWorks to divide
    // flash steps items

    // BUILD
    return (
        <div className={`grow h-[2px] bg-greyLight7 mt-32`}>
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletStepDivider