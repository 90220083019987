
function LandingMobileConfusedImage() {
    // Used in LandingMobileProblem to display
    // confused bear in mobile mode

    // BUILD
    return (
        <div className={"h-[64px] min-h-[64px] flex justify-center items-center"}>
            <img className={"object-contain w-full h-full"} src={'/images/SystemIceConfused.png'} alt="" />
        </div>
    );
}

// EXPORT
export default LandingMobileConfusedImage