
function CorrectionTabletGhostTopBar({handleClose}) {
    // Used in CorrectionStepScreen to display a transparent top bar
    // used to close current modal in desktop mode

    // BUILD
    return (
        <div className={"cursor-pointer hidden sm:block lg:hidden w-full h-[64px] min-h-[64px]"}
             onClick={handleClose}>
        </div>
    );
}

// EXPORT
export default CorrectionTabletGhostTopBar