import SystemCustomDivider from "../../dividers/SystemCustomDivider";
import LandingTabletUpperFooter from "./LandingTabletUpperFooter";
import LandingTabletLowerFooter from "./LandingTabletLowerFooter";

function LandingTabletFooter() {
    // Used in LandingScreen to display footer in mobile mode

    // BUILD
    return (
        <div className={"bg-background w-full flex flex-col space-y-8 px-24 py-40"}>
            <LandingTabletUpperFooter />
            <SystemCustomDivider color={'bg-greyLight3'} />
            <LandingTabletLowerFooter />
        </div>
    );
}

// EXPORT
export default LandingTabletFooter