import SystemCustomDivider from "../../dividers/SystemCustomDivider";

function LandingDivider() {
    // Used in LandingScreen to display divider
    // with internalized padding

    // BUILD
    return (
        <div className={"w-full px-24"}>
            <SystemCustomDivider color={'bg-greyLight5'} />
        </div>
    );
}

// EXPORT
export default LandingDivider