import {isNullOrUndefined} from "../../../services/Validator";

function QuestionsMobileLayout({children}) {
    // Used in QuestionsStepScreen to frame content
    // for mobile format

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"animate-appear overflow-auto w-full grow flex flex-col sm:hidden"}>
            {children}
        </div>
    );
}

// EXPORT
export default QuestionsMobileLayout