import {kSystemIcons} from "../../constants/icons";

function SystemMobileLeaveFlashIcon({onPressed, extraClass}) {
    // Used in FlashTopBar to leave current Flash

    // BUILD
    return (
        <div className={"animate-appear cursor-pointer h-[32px] text-greyLight7 hover:text-greyLight9 flex justify-end items-center -mr-2 " + extraClass}
             onClick={onPressed}>
            <i className={"material-icons text-[24px]"}>{kSystemIcons.logout}</i>
        </div>
    );
}

// EXPORT
export default SystemMobileLeaveFlashIcon