import {isNullOrUndefined} from "../../services/Validator";

function SystemDesktopTopBarLayout({children, extraClass}) {
    // Used through the app to frame top bar in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"w-full hidden h-[72px] min-h-[72px] max-h-[72px] lg:flex items-center justify-between px-32 " + extraClass}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemDesktopTopBarLayout