import LandingMobileUpperFooter from "./LandingMobileUpperFooter";
import LandingMobileLowerFooter from "./LandingMobileLowerFooter";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";

function LandingMobileFooter() {
    // Used in LandingScreen to display footer in mobile mode

    // BUILD
    return (
        <div className={"bg-backgroundLight1 w-full flex flex-col space-y-8 px-24 py-24"}>
            <LandingMobileUpperFooter />
            <SystemCustomDivider color={'bg-greyLight3'} />
            <LandingMobileLowerFooter />
        </div>
    );
}

// EXPORT
export default LandingMobileFooter