import LandingDesktopTabletTopBarActions from "../../others/landingScreen/LandingDesktopTabletTopBarActions";
import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemTabletTopBarLayout from "../../layouts/SystemTabletTopBarLayout";

function LandingTabletTopBar ({scrollToSolution}) {
    // Used in LandingScreen to display screen top bar

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.SMALL} />
            <LandingDesktopTabletTopBarActions scrollToSolution={scrollToSolution} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default LandingTabletTopBar