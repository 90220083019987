import ResultsQuestionsList from "../../lists/resultsStepScreen/ResultsQuestionsList";
import {isArrayNullUndefinedOrEmpty} from "../../../services/Validator";

function ResultsTabletQuestionsCard({questions}) {
    // Used in ResultsStepScreen to display questions list
    // in tablet mode

    // BUILD
    if (isArrayNullUndefinedOrEmpty(questions))
        return null;
    return (
        <div className={"overflow-auto w-full grow hidden lg:hidden sm:flex sm:flex-col bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] mt-24"}>
            <ResultsQuestionsList questions={questions} />
        </div>
    );
}

// EXPORT
export default ResultsTabletQuestionsCard