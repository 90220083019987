import LandingDesktopTabletTopBarNavigation from "./LandingDesktopTabletTopBarNavigation";
import LandingMobileMasterButton from "../../buttons/landingScreen/LandingMobileMasterButton";

function LandingDesktopTabletTopBarActions({scrollToSolution}) {
    // Used in

    // BUILD
    return (
        <div className={"flex items-center justify-end space-x-16"}>
            <LandingDesktopTabletTopBarNavigation scrollToSolution={scrollToSolution} />
            <LandingMobileMasterButton />
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletTopBarActions