import {forwardRef} from "react";
import LandingMobileSectionCoreText from "../../texts/landingScreen/LandingMobileSectionCoreText";
import LandingMobileSectionTitleText from "../../texts/landingScreen/LandingMobileSectionTitleText";
import LandingMobileConfusedImage from "../../images/landingScreen/LandingMobileConfusedImage";
import {kLandingTexts} from "../../../constants/texts";

const LandingMobileProblem = forwardRef((props, ref) => {
    // Used in LandingScreen to display app problem statement
    // in mobile mode

    // BUILD
    return (
        <div ref={ref}
             className={"w-full flex flex-col items-center justify-start px-24 py-48 space-y-24"}>
            <LandingMobileConfusedImage />
            <div className={"w-full flex flex-col space-y-12"}>
                <LandingMobileSectionTitleText text={kLandingTexts.problemTitle} />
                <LandingMobileSectionCoreText text={kLandingTexts.problemText} />
            </div>
        </div>
    );
})

// EXPORT
export default LandingMobileProblem