import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileStepItemText({text}) {
    // Used in LandingMobileStepItem to display step item
    // text

    // BUILD
    if (isNullOrUndefined(text))
        return null;
    return (
        <div className={"flex justify-center text-left"}>
            <span className={"text-white text-[16px] font-bold"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LandingMobileStepItemText