import SystemFlashLogoImage from "../../images/SystemFlashLogoImage";
import {FlashLogoTextSize} from "../../../controllers/FlashLogoTextController";
import SystemDesktopTabletLeaveFlashButton from "../../buttons/SystemDesktopTabletLeaveFlashButton";
import SystemTabletTopBarLayout from "../../layouts/SystemTabletTopBarLayout";
import SystemFlashSelectedGradeBadge from "../../badges/SystemFlashSelectedGradeBadge";
import SystemFlashTopBarLogoLayout from "../../layouts/SystemFlashTopBarLogoLayout";
import QuestionsDesktopTabletProgressBar from "./QuestionsDesktopTabletProgressBar";

function QuestionsTabletTopBar({onLeaveFlashPressed,
                                selectedGrade,
                                currentQuestion,
                                totalQuestion}) {
    // Used in QuestionsStepScreen to display top bar
    // in mobile mode

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL} />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <QuestionsDesktopTabletProgressBar currentQuestion={currentQuestion}
                                               totalQuestion={totalQuestion} />
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default QuestionsTabletTopBar