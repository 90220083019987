import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileSectionCoreText({text}) {
    // Used through landing components to display
    // section core text

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[16px] font-medium text-greyLight11"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LandingMobileSectionCoreText