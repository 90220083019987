

function SystemDialogClosingCrossIcon({onIconPress}) {
    // Used through the app and especially in dialogs to show
    // a closing modal/dialog cross icon

    // BUILD
    return(
        <div className="w-full flex justify-end"
             onClick={() => onIconPress()}>
            <i className={`material-icons cursor-pointer text-[24px] text-greyLight7 hover:text-greyLight9`}>
                cancel
            </i>
        </div>
    );
}

// EXPORT
export default SystemDialogClosingCrossIcon