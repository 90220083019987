import {Slide, Snackbar} from "@mui/material";

function ExistingFlashSnackBar({open, handleClose}) {
    // Used in FlashScreen to display snackbar about being back

    // PROPERTIES
    const anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right'
    }

    // BUILD
    return (
        <Snackbar anchorOrigin={anchorOrigin}
                  open={open}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  TransitionComponent={Slide}>
            <div className={"bg-primaryDark1 rounded-[8px] px-12 py-6 flex items-center justify-center w-[160px]"}>
                <span className={"font-semibold text-white text-[14px]"}>
                    {"Reprenons là où tu t'étais arrêté :)"}
                </span>
            </div>
        </Snackbar>
    );
}

// EXPORT
export default ExistingFlashSnackBar