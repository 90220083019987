

// PROPERTIES
export const BearTracksSize = {
    SMALL: "small",
    MEDIUM: "medium",
    TALL: "tall"
}

// METHODS
// ---- Render level badge size
export function renderBearTracksSize(bearTracksSize) {
    switch(bearTracksSize) {
        case BearTracksSize.SMALL:
            return "h-[72px] min-h-[72px]"
        case BearTracksSize.MEDIUM:
            return "h-[80px] min-h-[80px]"
        case BearTracksSize.TALL:
            return "h-[88px] min-h-[88px]"
        default:
            return "h-[88px] min-h-[88px]"
    }
}