
function SystemKnowledgeFrameTitle({background}) {
    // Used in SystemQuestionKnowledge to display
    // Knowledge border title in desktop, tablet and mobile modes

    // BUILD
    return (
        <div className={"absolute -top-[13px] right-14 flex space-x-6 justify-start items-center px-8 text-greyLight5 font-medium " + background}>
            <i className="material-icons text-[24px] lg:text-[20px]">
                {"favorite"}
            </i>
            <span className={"text-[14px]"}>
                {"Connaissance"}
            </span>
        </div>
    );
}

// EXPORT
export default SystemKnowledgeFrameTitle