import {CircularProgress} from "@mui/material";

function SystemQuestionPictureLoader() {
    // Used through the app to display one single screen centered
    // circular loader

    // BUILD
    return (
        <div className={'animate-appear mt-24'}>
            <CircularProgress size={"40px"} style={{'color' : '#DBE2EC'}} />
        </div>
    );
}

// EXPORT
export default SystemQuestionPictureLoader