import {useEffect, useRef, useState} from "react";
import LandingMobileTopBar from "../components/bars/landingScreen/LandingMobileTopBar";
import LandingMobileContentLayout from "../components/layouts/landingScreen/LandingMobileContentLayout";
import LandingMobileShowCase from "../components/others/landingScreen/LandingMobileShowCase";
import LandingMobileFooter from "../components/others/landingScreen/LandingMobileFooter";
import LandingMobileFinalCallToAction from "../components/others/landingScreen/LandingMobileFinalCallToAction";
import LandingMobileProblem from "../components/others/landingScreen/LandingMobileProblem";
import LandingMobileSolution from "../components/others/landingScreen/LandingMobileSolution";
import LandingDesktopTopBar from "../components/bars/landingScreen/LandingDesktopTopBar";
import LandingDesktopShowCase from "../components/others/landingScreen/LandingDesktopShowCase";
import LandingDesktopLayout from "../components/layouts/landingScreen/LandingDesktopLayout";
import LandingDesktopContentLayout
    from "../components/layouts/landingScreen/LandingDesktopContentLayout";
import LandingDesktopFooter from "../components/others/landingScreen/LandingDesktopFooter";
import LandingDesktopFinalCallToAction
    from "../components/others/landingScreen/LandingDesktopFinalCallToAction";
import LandingDesktopSolution from "../components/others/landingScreen/LandingDesktopSolution";
import LandingDesktopProblem from "../components/others/landingScreen/LandingDesktopProblem";
import LandingDivider from "../components/others/landingScreen/LandingDivider";
import {scrollToSection} from "../services/ScrollHandler";
import SystemCustomDivider from "../components/dividers/SystemCustomDivider";
import LandingTabletTopBar from "../components/bars/landingScreen/LandingTabletTopBar";
import LandingTabletLayout from "../components/layouts/landingScreen/LandingTabletLayout";
import LandingTabletShowCase from "../components/others/landingScreen/LandingTabletShowCase";
import LandingTabletContentLayout from "../components/layouts/landingScreen/LandingTabletContentLayout";
import LandingTabletProblem from "../components/others/landingScreen/LandingTabletProblem";
import LandingTabletFooter from "../components/others/landingScreen/LandingTabletFooter";
import LandingTabletSolution from "../components/others/landingScreen/LandingTabletSolution";
import LandingTabletFinalCallToAction from "../components/others/landingScreen/LandingTabletFinalCallToAction";
import {recordPageViewMetric} from "../controllers/SystemPageViewMetricController";
import {EventName} from "../enums/PageViewMetric";

function LandingScreen() {
    // Landing Screen used in App to display App purpose and functionalities

    // PROPERTIES
    // ---- State
    const [selectedOption, setSelectedOption] = useState(null)
    // ---- Refs
    const mobileProblemRef = useRef()
    const tabletProblemRef = useRef()
    const desktopProblemRef = useRef()
    const mobileSolutionRef = useRef()
    const tabletSolutionRef = useRef()
    const desktopSolutionRef = useRef()
    // ---- PageViewMetric
    const hasTrackedView = useRef(false);

    // HOOKS
    // ---- At rendering : if local flash is in storage, set flash variables
    useEffect(() => {
        if (!hasTrackedView.current) {
            // Avoid double trigger in dev, due to React Strict mode
            recordPageViewMetric(EventName.LANDING_VISIT);
            hasTrackedView.current = true;
        }
    }, []);

    // BUILD
    return(
        <div className={"overflow-auto bg-backgroundLight1 w-full h-[100svh] md:h-screen flex flex-col justify-start items-center"}>
            {/** MOBILE */}
            <LandingMobileTopBar scrollToSolution={() => scrollToSection(mobileSolutionRef)} />
            <LandingMobileContentLayout>
                <LandingMobileShowCase onBearTracksClicked={() => scrollToSection(mobileProblemRef)} />
                <LandingMobileProblem ref={mobileProblemRef} />
                <LandingDivider />
                <LandingMobileSolution ref={mobileSolutionRef}
                                       selectedOption={selectedOption}
                                       setSelectedOption={setSelectedOption} />
                <LandingDivider />
                <LandingMobileFinalCallToAction />
                <LandingMobileFooter />
            </LandingMobileContentLayout>
            {/** TABLET */}
            <LandingTabletTopBar scrollToSolution={() => scrollToSection(tabletSolutionRef)} />
            <LandingTabletLayout>
                <LandingTabletShowCase onBearTracksClicked={() => scrollToSection(tabletProblemRef)} />
                <LandingTabletContentLayout>
                    <LandingTabletProblem ref={tabletProblemRef} />
                    <SystemCustomDivider color={'bg-greyLight5'} />
                    <LandingTabletSolution ref={tabletSolutionRef}
                                            selectedOption={selectedOption}
                                            setSelectedOption={setSelectedOption} />
                    <SystemCustomDivider color={'bg-greyLight5'} />
                    <LandingTabletFinalCallToAction />
                </LandingTabletContentLayout>
                <LandingTabletFooter />
            </LandingTabletLayout>
            {/** DESKTOP */}
            <LandingDesktopTopBar scrollToSolution={() => scrollToSection(desktopSolutionRef)} />
            <LandingDesktopLayout>
                <LandingDesktopShowCase onBearTracksClicked={() => scrollToSection(desktopProblemRef)} />
                <LandingDesktopContentLayout>
                    <LandingDesktopProblem ref={desktopProblemRef} />
                    <SystemCustomDivider color={'bg-greyLight5'} />
                    <LandingDesktopSolution ref={desktopSolutionRef}
                                            selectedOption={selectedOption}
                                            setSelectedOption={setSelectedOption} />
                    <SystemCustomDivider color={'bg-greyLight5'} />
                    <LandingDesktopFinalCallToAction />
                </LandingDesktopContentLayout>
                <LandingDesktopFooter scrollToSolution={() => scrollToSection(desktopSolutionRef)} />
            </LandingDesktopLayout>
        </div>
    );
}

// EXPORT
export default LandingScreen