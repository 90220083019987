import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionOptionsLayout({children}) {
    // Used in QuestionsCard to frame question content
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full flex space-x-16 lg:space-x-24 items-start mt-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionOptionsLayout