
function CorrectionDesktopGhostTopBar({handleClose}) {
    // Used in CorrectionStepScreen to display a transparent top bar
    // used to close current modal in desktop mode

    // BUILD
    return (
        <div className={"cursor-pointer hidden lg:block w-full h-[72px] min-h-[72px]"}
             onClick={handleClose}>
        </div>
    );
}

// EXPORT
export default CorrectionDesktopGhostTopBar