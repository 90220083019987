import LandingDesktopTabletSideContentNavigation from "./LandingDesktopTabletSideContentNavigation";

function LandingDesktopTabletLowerFooter() {
    // Used in LandingDesktopFooter to display lower
    // part of footer in desktop/tablet mode

    // BUILD
    return (
        <div className={"flex items-center justify-between"}>
             <span className={"text-[16px] text-greyLight5 font-medium whitespace-nowrap"}>
                {"Ummia © tous droits réservés"}
            </span>
            <LandingDesktopTabletSideContentNavigation />
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletLowerFooter