import {isNullOrUndefined} from "../../../services/Validator";

function QuestionsMobileProgressText({currentQuestion, totalQuestion}) {
    // Used in QuestionsMobileSubTopBar to display
    // flash current progression text

    // BUILD
    if (isNullOrUndefined(currentQuestion) || isNullOrUndefined(totalQuestion))
        return null
    return(
        <div className="flex whitespace-nowrap space-x-2 text-[16px] font-medium leading-none">
            <span className="text-greyLight7 mr-2">{"Question"}</span>
            <span className="text-greyLight11">{currentQuestion}</span>
            <span className="text-greyLight7">/</span>
            <span className="text-greyLight7">{totalQuestion}</span>
        </div>
    );
}

// EXPORT
export default QuestionsMobileProgressText