import SystemCustomDivider from "../SystemCustomDivider";

function ResultsCardHeaderDivider() {
    // Used in ResultsScreen cards to slice card header from content

    // BUILD
    return (
        <SystemCustomDivider color="bg-greyLight5"
                             extraClass="mt-8 lg:mt-12 min-h-[1px]"/>
    );
}

// EXPORT
export default ResultsCardHeaderDivider