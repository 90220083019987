import LandingMobileLowerFooterItem from "../../items/landingScreen/LandingMobileLowerFooterItem";
import {routes} from "../../../config/routes";

function LandingMobileLowerFooter() {
    // Used in LandingMobileFooter to display side content
    // navigation links

    // BUILD
    return (
        <div className={"w-full flex justify-between space-x-12"}>
            <LandingMobileLowerFooterItem label={"À propos"}
                                          route={routes.about} />
            <LandingMobileLowerFooterItem label={"Mentions légales"}
                                          route={routes.legals} />
            <LandingMobileLowerFooterItem label={"Politique de confidentialité"}
                                          route={routes.privacy} />
        </div>
    );
}

// EXPORT
export default LandingMobileLowerFooter