import LandingBearTracksButton from "../../buttons/landingScreen/LandingBearTracksButton";
import {BearTracksSize} from "../../../controllers/BearTracksButtonController";
import LandingTabletMasterTitleText from "../../texts/landingScreen/LandingTabletMasterTitleText";
import LandingTabletMasterSubtitleText from "../../texts/landingScreen/LandingTabletMasterSubtitleText";
import LandingTabletMasterButton from "../../buttons/landingScreen/LandingTabletMasterButton";
import {kLandingTexts} from "../../../constants/texts";

function LandingTabletShowCase({onBearTracksClicked}) {
    // Used in LadingScreen to display master content
    // in tablet mode

    // BUILD
    return (
        <div className={"bg-backgroundLight1 w-full h-[calc(100vh-64px)] min-h-[calc(100vh-64px)] flex flex-col items-center pb-24"}>
            <div className={"w-[560px] grow flex flex-col justify-between"}>
                <div className={"w-full grow flex flex-col space-y-48 justify-center"}>
                    <div className={"w-full flex flex-col space-y-24"}>
                        <LandingTabletMasterTitleText title={kLandingTexts.punchline} />
                        <LandingTabletMasterSubtitleText subtitle={kLandingTexts.subPunchline} />
                    </div>
                    <LandingTabletMasterButton />
                </div>
                <LandingBearTracksButton onBearTracksClicked={onBearTracksClicked}
                                         bearTracksSize={BearTracksSize.MEDIUM} />
            </div>
        </div>
    );
}

// EXPORT
export default LandingTabletShowCase