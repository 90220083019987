import {forwardRef} from "react";
import LandingTabletWayItWorks from "./LandingTabletWayItWorks";
import LandingTabletSolutionOne from "./LandingTabletSolutionOne";
import LandingTabletSolutionTwo from "./LandingTabletSolutionTwo";

const LandingTabletSolution = forwardRef(({selectedOption, setSelectedOption}, ref) => {
    // Used in LandingScreen to display solution block in Desktop / Tablet mode

    // BUILD
    return (
        <div ref={ref}
             className={"w-full flex flex-col py-56 space-y-40"}>
            <LandingTabletWayItWorks />
            <LandingTabletSolutionOne selectedOption={selectedOption}
                                       setSelectedOption={setSelectedOption} />
            <LandingTabletSolutionTwo />
        </div>
    );
})

// EXPORT
export default LandingTabletSolution