import {isNullOrUndefined} from "../../../services/Validator";

function LandingDesktopContentLayout({children}) {
    // Used in LandingScreen to frame content in desktop
    // mode

    // BUILD
    if(isNullOrUndefined(children))
        return null
    return (
        <div className={"bg-backgroundLight3 w-full flex flex-col items-center justify-start"}>
            <div className={"md:w-[708px] flex flex-col items-center justify-start"}>
                {children}
            </div>
        </div>
    );
}

// EXPORT
export default LandingDesktopContentLayout