import {Link} from "react-router-dom";
import {isNullOrUndefined} from "../../../services/Validator";

function LandingTabletLowerFooterItem({label, route}) {
    // Used in LandingTabletLowerFooter to display one single
    // footer navigation link in tablet mode

    // BUILD
    if (isNullOrUndefined(label))
        return null
    return (
        <Link to={route}>
            <div className={"cursor-pointer text-greyLight5 hover:text-greyLight9 leading-tight"}>
                <span className={"text-[16px] font-medium"}>
                    {label}
                </span>
            </div>
        </Link>
    );
}

// EXPORT
export default LandingTabletLowerFooterItem