
function ResultsQuestionDivider() {
    // Used in ResultsQuestionsList to separate
    // questions

    // BUILD
    return(
        <div className="pl-16 flex items-center relative pr-16">
            <img className="-mr-2" src={"/images/SystemQuestionDividerStar.png"} style={{height: 10}} alt={""}/>
            <div className={"w-full h-[1px] bg-greyLight5"}> </div>
        </div>
    );
}

// EXPORT
export default ResultsQuestionDivider