import {kResultsMobileNavigation} from "../../../constants/navigation";
import SystemMobileTabBarItem from "../../items/SystemMobileTabBarItem";

function ResultsMobileTabBar({selectedTab, setSelectedTab}) {
    // Used in ResultsStepScreen to show tab bar for graphs and questions list
    // display

    // BUILD
    return (
        <div className={"w-full h-[48px] min-h-[48px] flex border-b border-t border-greyLight7 sticky sm:hidden"}>
            {kResultsMobileNavigation.map((item, index) => (
                <SystemMobileTabBarItem
                    key={index}
                    item={item}
                    isSelected={selectedTab === index}
                    setSelectedTab={() => setSelectedTab(index)}/>
            ))}
        </div>
    );
}

// EXPORT
export default ResultsMobileTabBar