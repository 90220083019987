import {kMethodQuestion} from "../../../constants/objects";
import LandingMobileSectionCoreText from "../../texts/landingScreen/LandingMobileSectionCoreText";
import LandingMobileSectionTitle from "./LandingMobileSectionTitle";
import SystemDraftQuestionCard from "../../cards/SystemDraftQuestionCard";
import {kLandingTexts} from "../../../constants/texts";

function LandingMobileSolutionOne({selectedOption, setSelectedOption}) {
    // Used in LandingMobileSolution to display solution one in
    // mobile one

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 items-start justify-start"}>
            <div className={"w-full flex flex-col space-y-8 items-start justify-start"}>
                <LandingMobileSectionTitle icon={"bolt"}
                                           text={kLandingTexts.solutionOneTitle} />
                <LandingMobileSectionCoreText text={kLandingTexts.solutionOneText} />
            </div>
            <SystemDraftQuestionCard question={kMethodQuestion}
                                     selectedOption={selectedOption}
                                     setSelectedOption={setSelectedOption}
                                     isForMobile={true} />
        </div>
    );
}

// EXPORT
export default LandingMobileSolutionOne