import {DeviceType} from "../enums/DeviceType";

// Used to determine screen related info

// METHODS
// ---- Scan screen size to determine device type
export function scanDeviceType() {
    // MediaQuery
    const width = window.innerWidth;
    // Return
    if (width < 768) {
        return DeviceType.MOBILE
    } else if (width >= 768 && width <= 1024) {
        return DeviceType.TABLET
    } else {
        return DeviceType.DESKTOP
    }
}