import {isNullOrUndefined} from "../../../services/Validator";

function LandingDesktopMasterTitleText({title}) {
    // Used in LandingDesktopShowCase to display
    // landing title text

    // BUILD
    if(isNullOrUndefined(title))
        return null
    return (
        <div className={"w-full text-center leading-tight"}>
            <span className={"text-white font-bold text-[40px]"}>
               {title}
            </span>
        </div>
    );
}

// EXPORT
export default LandingDesktopMasterTitleText