
function QuestionsDesktopTabletProgressText({currentQuestion, totalQuestion}) {
    // Used in QuestionsDesktopTabletProgressBar to display progress
    // text in both tablet and desktop modes

    // BUILD
    return (
        <div className="w-[124px] flex justify-end items-center space-x-4 pb-1 whitespace-nowrap  text-[16px] font-medium leading-none">
            <span className="text-greyLight7">Question</span>
            <span className="text-greyLight11">{currentQuestion ?? "?"}</span>
            <span className="text-greyLight7">/</span>
            <span className="text-greyLight7">{totalQuestion ?? "?"}</span>
        </div>
    );
}

// EXPORT
export default QuestionsDesktopTabletProgressText