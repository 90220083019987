import SystemMobileFlashLogoImage from "../../images/SystemMobileFlashLogoImage";
import SystemLeaveFlashIcon from "../../icons/SystemLeaveFlashIcon";
import ResultsMobileTopBarTitleText from "../../texts/resultsStepScreen/ResultsMobileTopBarTitleText";
import SystemMobileTopBarLayout from "../../layouts/SystemMobileTopBarLayout";
import SystemFlashSelectedGradeBadge from "../../badges/SystemFlashSelectedGradeBadge";

function ResultsMobileTopBar({selectedGrade, onLeaveFlashPressed}) {
    // Used in ResultsStepScreen to display flash logo, title and
    // leave flash icon in mobile mode

    // BUILD
    return (
        <SystemMobileTopBarLayout>
            <div className={"w-[64px] min-w-[64px] flex justify-start items-center space-x-6"}>
                <SystemMobileFlashLogoImage />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </div>
            <ResultsMobileTopBarTitleText />
            <SystemLeaveFlashIcon onPressed={onLeaveFlashPressed}
                                  extraClass={"w-[64px] min-w-[64px]"} />
        </SystemMobileTopBarLayout>
    );
}

// EXPORT
export default ResultsMobileTopBar