import ResultsScore from "./ResultsScore";
import ResultsSummaryDivider from "../../dividers/resultsStepScreen/ResultsSummaryDivider";
import ResultsDetailedScores from "./ResultsDetailedScores";


function ResultsSummary({scores, total}) {
    // Used in ResultsStepScreen to display flash scores
    // summary

    // BUILD
    return (
        <div className={"h-[168px] min-h-[168px] w-full flex  sm:hidden px-16"}>
            <ResultsScore correctAnswers={scores[0]}
                          total={total}/>
            <ResultsSummaryDivider />
            <ResultsDetailedScores good={scores[0]}
                                   bad={scores[1]}
                                   neutral={scores[2]} />
        </div>
    );
}


// EXPORT
export default ResultsSummary