import {isNullOrUndefined} from "../../../services/Validator";

function LandingDesktopMasterSubtitleText({subtitle}) {
    // Used in

    // BUILD
    if(isNullOrUndefined(subtitle))
        return null
    return (
        <div className={"w-full text-center px-16"}>
            <span className={"text-greyLight11 font-medium text-[24px]"}>
               {subtitle}
            </span>
        </div>
    );
}

// EXPORT
export default LandingDesktopMasterSubtitleText