import LandingDesktopTabletNavigationText from "../../texts/landingScreen/LandingDesktopTabletNavigationText";


function LandingDesktopTabletTopBarNavigation({scrollToSolution}) {
    // Used in LandingDesktopTabletTopBarActions to display list of navigation items

    // BUILD
    return(
        <div className='flex items-center space-x-16'>
            <LandingDesktopTabletNavigationText navText={'Fonctionnement'} scrollToElement={() => scrollToSolution()} />
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletTopBarNavigation