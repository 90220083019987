import {isNullOrUndefined} from "../../../services/Validator";

function ResultsMobileContentLayout({children}) {
    // Used in ResultsStepScreen to frame content in mobile
    // mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] w-full grow flex flex-col sm:hidden items-center justify-start rounded-t-[16px]"}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsMobileContentLayout