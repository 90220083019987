
function LandingDesktopStepItem({icon, text}) {
    // Used in LandingDesktopWayItWorks to display one single
    // step element in desktop mode

    // BUILD
    return (
        <div className={"flex flex-col items-center justify-start space-y-8"}>
            <div className={"h-[64px] min-h-[64px] w-[64px] min-w-[64px] rounded-full border-[1px] border-primaryLight5 leading-none flex items-center justify-center"}>
                <i className="material-icons text-[40px] text-primaryLight5">{icon}</i>
            </div>
            <div className={"w-[120px] min-w-[120px] flex justify-center text-center"}>
                <span className={"text-white text-[16px] font-semibold"}>
                    {text}
                </span>
            </div>
        </div>
    );
}

// EXPORT
export default LandingDesktopStepItem