import {isNullOrUndefined} from "../../../services/Validator";


function CorrectionDesktopLayout({children}) {
    // Used in CorrectionStepScreen to frame content
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"relative overflow-auto bg-backgroundLight3 w-full grow hidden lg:flex lg:flex-col items-center justify-start rounded-t-[32px]"}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionDesktopLayout