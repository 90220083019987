import LandingDesktopTabletNavigationText from "../../texts/landingScreen/LandingDesktopTabletNavigationText";

function LandingDesktopTabletMainNavigation({scrollToSolution}) {
    // Used in

    // BUILD
    return (
        <div className={"flex items-center justify-end space-x-24"}>
            <LandingDesktopTabletNavigationText navText={'Fonctionnement'}
                                                scrollToElement={() => scrollToSolution()} />
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletMainNavigation