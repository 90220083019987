import {useState} from "react";
import ResultsAppreciationDialog from "../../dialogs/resultsStepScreen/ResultsAppreciationDialog";
import ResultsTotalScore from "./ResultsTotalScore";
import ResultsScoreEmojiImage from "../../images/resultsStepScreen/ResultsScoreEmojiImage";
import {isNullOrUndefined} from "../../../services/Validator";

function ResultsScore({correctAnswers, total}) {
    // Used in ResultsScreen component to display
    // flash score

    // PROPERTIES
    const [isAppreciationModalOpen, setIsAppreciationModalOpen] = useState(false)
    let successRatio = (isNullOrUndefined(correctAnswers) || isNullOrUndefined(total)) ? null : Math.round(100*correctAnswers/total)

    // BUILD
    if (isNullOrUndefined(successRatio))
        return null;
    return (
        <div className="w-[112px] min-w-[112px] h-full flex flex-col items-center">
            {/** MODAL */}
            <ResultsAppreciationDialog open={isAppreciationModalOpen}
                                       handleClose={() => setIsAppreciationModalOpen(false)}
                                       successRatio={successRatio} />
            {/** CORE */}
            <ResultsTotalScore correctAnswers={correctAnswers}
                               total={total} />
            <ResultsScoreEmojiImage successRatio={successRatio}
                                    openAppreciation={() => setIsAppreciationModalOpen(true)} />
        </div>
    );
}

// EXPORT
export default ResultsScore