
// Used to provide fake and static data for tests or debugging

// PROPERTIES
export const fakerFlashGrades = [
    {
        "id": 7,
        "name": "CM1",
        "rank": 7,
        "shortDescription": "La classe de CM1 correspond au premier niveau du cycle 3, la s\u00e9rie ne comportera donc que des questions de ce niveau",
        "gradeCycle": {
            "id": 3,
            "name": "Cycle 3",
            "shortDescription": "Le cycle 3, ou cycle de consolidation, comprend le cours moyen premi\u00e8re ann\u00e9e (CM1) et le cours moyen deuxi\u00e8me ann\u00e9e (CM2) ; le cycle de consolidation se poursuit au coll\u00e8ge, en classe de sixi\u00e8me."
        }
    },
    {
        "id": 8,
        "name": "CM2",
        "rank": 8,
        "shortDescription": "La classe de CM2 correspond au deuxi\u00e8me niveau du cycle 3, la s\u00e9rie sera donc compos\u00e9e de questions de CM1 et de CM2",
        "gradeCycle": {
            "id": 3,
            "name": "Cycle 3",
            "shortDescription": "Le cycle 3, ou cycle de consolidation, comprend le cours moyen premi\u00e8re ann\u00e9e (CM1) et le cours moyen deuxi\u00e8me ann\u00e9e (CM2) ; le cycle de consolidation se poursuit au coll\u00e8ge, en classe de sixi\u00e8me."
        }
    },
    {
        "id": 9,
        "name": "6\u1d49",
        "rank": 9,
        "shortDescription": "La classe de 6\u1d49 est le dernier niveau du cycle 3, la s\u00e9rie sera donc compos\u00e9e de questions de CM1, CM2 et 6\u1d49",
        "gradeCycle": {
            "id": 3,
            "name": "Cycle 3",
            "shortDescription": "Le cycle 3, ou cycle de consolidation, comprend le cours moyen premi\u00e8re ann\u00e9e (CM1) et le cours moyen deuxi\u00e8me ann\u00e9e (CM2) ; le cycle de consolidation se poursuit au coll\u00e8ge, en classe de sixi\u00e8me."
        }
    },
    {
        "id": 10,
        "name": "5\u1d49",
        "rank": 10,
        "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
        "gradeCycle": {
            "id": 4,
            "name": "Cycle 4",
            "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
        }
    },
    {
        "id": 11,
        "name": "4\u1d49",
        "rank": 11,
        "shortDescription": "La classe de 4\u1d49 correspond au deuxi\u00e8me niveau du cycle 4, la s\u00e9rie sera donc compos\u00e9e de questions de 5\u1d49 et de 4\u1d49",
        "gradeCycle": {
            "id": 4,
            "name": "Cycle 4",
            "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
        }
    },
    {
        "id": 12,
        "name": "3\u1d49",
        "rank": 12,
        "shortDescription": "La classe de 3\u1d49 est le dernier niveau du cycle 4, la s\u00e9rie sera donc compos\u00e9e de questions de 5\u1d49, 4\u1d49 et 3\u1d49",
        "gradeCycle": {
            "id": 4,
            "name": "Cycle 4",
            "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
        }
    }
]
export const fakerSubthemesWithLevelAs = [
    {
        "id": 2,
        "name": "Base des nombres relatifs",
        "shortDescription": null,
        "isPublished": true,
        "subthemeTheme": {
            "id": 1,
            "identifier": 0,
            "name": "Nombres et calcul",
            "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
        },
        "subthemeLevelAs": [
            {
                "id": 4,
                "identifier": 319,
                "task": "D\u00e9terminer l\u0027oppos\u00e9 d\u0027un nombre relatif",
                "isPublished": true
            },
            {
                "id": 5,
                "identifier": 190,
                "task": "Rep\u00e9rer un point avec un nombre relatif sur une droite gradu\u00e9e",
                "isPublished": true
            }
        ]
    },
    {
        "id": 5,
        "name": "Calcul avec les nombres relatifs",
        "shortDescription": null,
        "isPublished": true,
        "subthemeTheme": {
            "id": 1,
            "identifier": 0,
            "name": "Nombres et calcul",
            "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
        },
        "subthemeLevelAs": [
            {
                "id": 8,
                "identifier": 142,
                "task": "Additionner des nombres relatifs de m\u00eame signe",
                "isPublished": true
            },
            {
                "id": 9,
                "identifier": 143,
                "task": "Additionner des nombres relatifs de signes diff\u00e9rents",
                "isPublished": true
            }
        ]
    },
    {
        "id": 3,
        "name": "Rep\u00e9rage dans le plan",
        "shortDescription": null,
        "isPublished": true,
        "subthemeTheme": {
            "id": 1,
            "identifier": 0,
            "name": "Nombres et calcul",
            "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
        },
        "subthemeLevelAs": [
            {
                "id": 6,
                "identifier": 182,
                "task": "Se rep\u00e9rer dans le plan",
                "isPublished": true
            }
        ]
    }
]
export const fakerQuestions = [
    {
        "id": 52,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point B ?",
        "isVerified": true,
        "options": [
            {
                "id": 154,
                "proposal": "\\( (1 \\\\; 4) \\)",
                "explanation": "La position du point B sur l\u0027axe horizontal est : \\( 1 \\)\r\nLa position sur l\u0027axe vertical est : \\( 4 \\).\r\nSes coordonn\u00e9es sont (1; 4)",
                "isCorrect": true,
                "question": 52
            },
            {
                "id": 156,
                "proposal": "\\( (1\\ 4) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 52
            },
            {
                "id": 155,
                "proposal": "\\( (4 \\\\; 1) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 52
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 18,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1270-110-66de085967d1b260519403-66f18283c93f6104008002.png",
            "size": 19495,
            "question": 52,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1270-110-66de085967d1b260519403-66f18283c93f6104008002.png",
            "ratio": 5.33
        }
    },
    {
        "id": 53,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point C ?",
        "isVerified": true,
        "options": [
            {
                "id": 159,
                "proposal": "\\( (3\\ 5) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 53
            },
            {
                "id": 158,
                "proposal": "\\( (5 \\\\; 3) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 53
            },
            {
                "id": 157,
                "proposal": "\\( (3 \\\\; 5) \\)",
                "explanation": "La position du point C sur l\u0027axe horizontal est : \\( 3 \\)\r\nLa position sur l\u0027axe vertical est : \\( 5 \\).\r\nSes coordonn\u00e9es sont (3; 5)",
                "isCorrect": true,
                "question": 53
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 14,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1271-119-66dea6d5a68a1384016268-66f1825bc7675094616935.png",
            "size": 17664,
            "question": 53,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1271-119-66dea6d5a68a1384016268-66f1825bc7675094616935.png",
            "ratio": 5.33
        }
    },
    {
        "id": 51,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point A ?",
        "isVerified": true,
        "options": [
            {
                "id": 153,
                "proposal": "\\( (2\\ 3) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 51
            },
            {
                "id": 151,
                "proposal": "\\( (2 \\\\; 3) \\)",
                "explanation": "La position du point A sur l\u0027axe horizontal est : \\( 2 \\)\r\nLa position sur l\u0027axe vertical est : \\( 3 \\).\r\nSes coordonn\u00e9es sont (2; 3)",
                "isCorrect": true,
                "question": 51
            },
            {
                "id": 152,
                "proposal": "\\( (3 \\\\; 2) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 51
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 13,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1271-118-66dea68a5ee89620781659-66f18251c9154634634229.png",
            "size": 18176,
            "question": 51,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1271-118-66dea68a5ee89620781659-66f18251c9154634634229.png",
            "ratio": 5.33
        }
    },
    {
        "id": 54,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point D ?",
        "isVerified": true,
        "options": [
            {
                "id": 162,
                "proposal": "\\( (-3\\ 4) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 54
            },
            {
                "id": 160,
                "proposal": "\\( (-3 \\\\; 4) \\)",
                "explanation": "La position du point D sur l\u0027axe horizontal est : \\( -3 \\)\r\nLa position sur l\u0027axe vertical est : \\( 4 \\).\r\nSes coordonn\u00e9es sont (-3; 4)",
                "isCorrect": true,
                "question": 54
            },
            {
                "id": 161,
                "proposal": "\\( (4 \\\\; -3) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 54
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 19,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1270-109-66de0803b2dc8141917970-66f1828cf015a390870517.png",
            "size": 18264,
            "question": 54,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1270-109-66de0803b2dc8141917970-66f1828cf015a390870517.png",
            "ratio": 5.33
        }
    },
    {
        "id": 57,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point G ?",
        "isVerified": true,
        "options": [
            {
                "id": 169,
                "proposal": "\\( (5 \\\\; -2) \\)",
                "explanation": "La position du point G sur l\u0027axe horizontal est : \\( 5 \\)\r\nLa position sur l\u0027axe vertical est : \\( -2 \\).\r\nSes coordonn\u00e9es sont (5; -2)",
                "isCorrect": true,
                "question": 57
            },
            {
                "id": 171,
                "proposal": "\\( (5\\ -2) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 57
            },
            {
                "id": 170,
                "proposal": "\\( (-2 \\\\; 5) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 57
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 16,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1271-116-66dea5f244d25735706524-66f182711d822582677913.png",
            "size": 15883,
            "question": 57,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1271-116-66dea5f244d25735706524-66f182711d822582677913.png",
            "ratio": 5.33
        }
    },
    {
        "id": 60,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point J ?",
        "isVerified": true,
        "options": [
            {
                "id": 179,
                "proposal": "\\( (-4 \\\\; -3) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 60
            },
            {
                "id": 178,
                "proposal": "\\( (-3 \\\\; 4) \\)",
                "explanation": "La position du point J sur l\u0027axe horizontal est : \\( -3 \\)\r\nLa position sur l\u0027axe vertical est : \\( -4 \\).\r\nSes coordonn\u00e9es sont (-3; -4)",
                "isCorrect": true,
                "question": 60
            },
            {
                "id": 180,
                "proposal": "\\( (-3\\ -4) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 60
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 20,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1270-104-66de0688e22fd320189179-66f182956d8b5457358560.png",
            "size": 18638,
            "question": 60,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1270-104-66de0688e22fd320189179-66f182956d8b5457358560.png",
            "ratio": 5.33
        }
    },
    {
        "id": 59,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point I ?",
        "isVerified": true,
        "options": [
            {
                "id": 176,
                "proposal": "\\( (-2 \\\\; -4) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 59
            },
            {
                "id": 177,
                "proposal": "\\( (-4\\ -2) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 59
            },
            {
                "id": 175,
                "proposal": "\\( (-4 \\\\; -2) \\)",
                "explanation": "La position du point I sur l\u0027axe horizontal est : \\( -4 \\)\r\nLa position sur l\u0027axe vertical est : \\( -2 \\).\r\nSes coordonn\u00e9es sont (-4; -2)",
                "isCorrect": true,
                "question": 59
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 17,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1271-114-66dea5362da02066971502-66f1827a90fcd266086483.png",
            "size": 15942,
            "question": 59,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1271-114-66dea5362da02066971502-66f1827a90fcd266086483.png",
            "ratio": 5.33
        }
    },
    {
        "id": 56,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point F ?",
        "isVerified": true,
        "options": [
            {
                "id": 166,
                "proposal": "\\( (-4 \\\\; 2) \\)",
                "explanation": "La position du point F sur l\u0027axe horizontal est : \\( -4 \\)\r\nLa position sur l\u0027axe vertical est : \\( 2 \\).\r\nSes coordonn\u00e9es sont (-4; 2)",
                "isCorrect": true,
                "question": 56
            },
            {
                "id": 167,
                "proposal": "\\( (2 \\\\; -4) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 56
            },
            {
                "id": 168,
                "proposal": "\\( (-4\\ 2) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 56
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 22,
            "file": null,
            "width": "1281",
            "height": "481",
            "name": "52-137-66d70b9bac8eb725160440-66f182a615459213973800.png",
            "size": 72618,
            "question": 56,
            "filePath": "http://172.16.238.11:9000/questions/52-137-66d70b9bac8eb725160440-66f182a615459213973800.png",
            "ratio": 2.66
        }
    },
    {
        "id": 58,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point H ?",
        "isVerified": true,
        "options": [
            {
                "id": 173,
                "proposal": "\\( (-1 \\\\; 3) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 58
            },
            {
                "id": 172,
                "proposal": "\\( (3 \\\\; -1) \\)",
                "explanation": "La position du point H sur l\u0027axe horizontal est : \\( 3 \\)\r\nLa position sur l\u0027axe vertical est : \\( -1 \\).\r\nSes coordonn\u00e9es sont (3; -1)",
                "isCorrect": true,
                "question": 58
            },
            {
                "id": 174,
                "proposal": "\\( (3\\ -1) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 58
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 21,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1270-102-66de05c68341b672386080-66f1829daeeaf624449151.png",
            "size": 17115,
            "question": 58,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1270-102-66de05c68341b672386080-66f1829daeeaf624449151.png",
            "ratio": 5.33
        }
    },
    {
        "id": 55,
        "query": "Dans le rep\u00e8re ci-contre, quelles sont les coordonn\u00e9es du point E ?",
        "isVerified": true,
        "options": [
            {
                "id": 164,
                "proposal": "\\( (5 \\\\; -1) \\)",
                "explanation": "L\u0027abscisse et l\u0027ordonn\u00e9e ont \u00e9t\u00e9 invers\u00e9s.",
                "isCorrect": false,
                "question": 55
            },
            {
                "id": 163,
                "proposal": "\\( (-1 \\\\; 5) \\)",
                "explanation": "La position du point E sur l\u0027axe horizontal est : \\( -1 \\)\r\nLa position sur l\u0027axe vertical est : \\( 5 \\).\r\nSes coordonn\u00e9es sont (-1; 5)",
                "isCorrect": true,
                "question": 55
            },
            {
                "id": 165,
                "proposal": "\\( (-1\\ 5) \\)",
                "explanation": "Pour s\u00e9parer l\u0027abscisse de l\u0027ordonn\u00e9e, il ne faut pas oublier le point virgule.",
                "isCorrect": false,
                "question": 55
            }
        ],
        "levelA": {
            "id": 6,
            "identifier": 182,
            "task": "Se rep\u00e9rer dans le plan",
            "isPublished": true,
            "levelAGrade": {
                "id": 10,
                "name": "5\u1d49",
                "rank": 10,
                "shortDescription": "La classe de 5\u1d49 correspond au premier niveau du cycle 4, la s\u00e9rie ne comportera donc que des questions de ce niveau",
                "gradeCycle": {
                    "id": 4,
                    "name": "Cycle 4",
                    "shortDescription": "Le cycle 4, ou cycle des approfondissements, comprend les classes de cinqui\u00e8me, quatri\u00e8me et troisi\u00e8me."
                }
            },
            "levelASubtheme": {
                "id": 3,
                "name": "Rep\u00e9rage dans le plan",
                "shortDescription": null,
                "isPublished": true,
                "subthemeTheme": {
                    "id": 1,
                    "identifier": 0,
                    "name": "Nombres et calcul",
                    "shortDescription": "Dans ce th\u00e8me, tu vas pratiquer diff\u00e9rents types de calculs comme le calcul pos\u00e9, le calcul litt\u00e9ral, le calcul mental et le calcul r\u00e9fl\u00e9chi. Et ce, avec de nouveaux types de nombre et notamment les fractions et les nombres relatifs."
                }
            },
            "levelAKnowledge": {
                "id": 6,
                "identifier": 182,
                "keyword": "Se rep\u00e9rer dans le plan",
                "definition": "Pour rep\u00e9rer un point dans un rep\u00e8re, on cherche :\n- son abscisse, sa position sur l\u0027axe horizontal\n- son ordonn\u00e9e, sa position sur l\u0027axe vertical\n- on note le tout sous la forme de coordonn\u00e9es (abscisse ; ordonn\u00e9e).",
                "type": "Method"
            }
        },
        "questionPicture": {
            "id": 15,
            "file": null,
            "width": "1280",
            "height": "240",
            "name": "plan-de-travail-1271-119-66dea6d5a68a1384016268-66f18266050df310883547.png",
            "size": 17664,
            "question": 55,
            "filePath": "http://172.16.238.11:9000/questions/plan-de-travail-1271-119-66dea6d5a68a1384016268-66f18266050df310883547.png",
            "ratio": 5.33
        }
    }
]
