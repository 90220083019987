import QuestionsOptionsList from "../../lists/questionsStepScreen/QuestionsOptionsList";
import QuestionsCardContentLayout
    from "../../layouts/questionsStepScreen/QuestionsCardContentLayout";
import SystemQuestionCardQueryText from "../../texts/SystemQuestionCardQueryText";
import SystemQuestionCardHeader from "../SystemQuestionCardHeader";
import {get_nested} from "../../../services/JsonHandler";
import {isNullOrUndefined} from "../../../services/Validator";
import SystemQuestionPicture from "../../images/SystemQuestionPicture";
import {displayLandscapeOrSquare,
        displayPortrait
} from "../../../controllers/questionsStepScreen/QuestionPictureController";
import SystemZoomedPictureDialog from "../../dialogs/SystemZoomedPictureDialog";
import {useState} from "react";

function QuestionsCard ({question,
                         optionSelected,
                         setOptionSelected,
                         setOpenDontKnowDialog}) {
    // Used in QuestionsStepScreen to display question content in a card
    // in desktop and tablet format

    // PROPERTIES
    // ---- Modal
    const [isPictureModalOpen, setIsPictureModalOpen] = useState(false)
    // ---- Question
    const questionTask = get_nested(['levelA', 'task'], question, null)
    const questionSubtheme = get_nested(['levelA', 'levelASubtheme', 'name'], question, null)
    const questionOptions = get_nested(['options'], question, null)
    const questionStatement = get_nested(['query'], question, null)
    const questionPicture = get_nested(['questionPicture'], question, null)
    const questionPictureUrl = get_nested(['questionPicture', 'filePath'], question, null)
    const questionPictureRatio = get_nested(['questionPicture', 'ratio'], question, null)

    // BUILD
    if (isNullOrUndefined(question))
        return null;
    return (
        <div className={"w-full grow"}>
            <div className={"overflow-auto w-full pb-16 lg:pb-24 flex flex-col justify-start items-center"}>
                {/** MODAL */}
                <SystemZoomedPictureDialog open={isPictureModalOpen}
                                           handleClose={() => setIsPictureModalOpen(false)}
                                           pictureUrl={questionPictureUrl}
                                           pictureRatio={questionPictureRatio} />
                {/** CORE */}
                <SystemQuestionCardHeader subtheme={questionSubtheme}
                                          task={questionTask}/>
                <SystemQuestionCardQueryText query={questionStatement}/>
                {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.LANDSCAPE}*/}
                {/*                                  isForMobile={false} />*/}
                {displayLandscapeOrSquare(questionPicture, questionPictureUrl, questionPictureRatio)
                    ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                             pictureRatio={questionPictureRatio}
                                             isForMobile={false}
                                             openZoom={() => setIsPictureModalOpen(true)} />
                    : null}
                <QuestionsCardContentLayout>
                    {displayPortrait(questionPicture, questionPictureUrl, questionPictureRatio)
                        ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                                 pictureRatio={questionPictureRatio}
                                                 isForMobile={false}
                                                 openZoom={() => setIsPictureModalOpen(true)} />
                        : null}
                    {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.PORTRAIT}*/}
                    {/*                                  isForMobile={false} />*/}
                    <QuestionsOptionsList options={questionOptions}
                                          optionSelected={optionSelected}
                                          setOptionSelected={setOptionSelected}
                                          onDontKnowPress={() => setOpenDontKnowDialog(true)}/>
                </QuestionsCardContentLayout>
            </div>
        </div>
    );
}

// EXPORT
export default QuestionsCard