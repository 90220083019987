import LandingDesktopSectionCoreText from "../../texts/landingScreen/LandingDesktopSectionCoreText";
import LandingDesktopSectionTitleText from "../../texts/landingScreen/LandingDesktopSectionTitleText";
import {kMethodQuestion} from "../../../constants/objects";
import SystemDraftQuestionCard from "../../cards/SystemDraftQuestionCard";
import {kLandingTexts} from "../../../constants/texts";

function LandingDesktopSolutionOne({selectedOption, setSelectedOption}) {
    // Used in LandingDesktopSolution to display block about correction
    // in desktop mode

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 items-start justify-start"}>
            <div className={"w-full flex flex-col space-y-12 items-start justify-start"}>
                <div className={"flex justify-start items-center space-x-8"}>
                    <i className="material-icons text-[32px] text-white">{"bolt"}</i>
                    <LandingDesktopSectionTitleText text={kLandingTexts.solutionOneTitle} />
                </div>
                <LandingDesktopSectionCoreText text={kLandingTexts.solutionOneText} />
            </div>
            <SystemDraftQuestionCard question={kMethodQuestion}
                                     selectedOption={selectedOption}
                                     setSelectedOption={setSelectedOption} />
        </div>
    );
}

// EXPORT
export default LandingDesktopSolutionOne