import {renderOptionJudgmentColor, renderOptionJudgmentText} from "../../controllers/OptionJudgementTextController";
import {isNullOrUndefined} from "../../services/Validator";
import {get_nested} from "../../services/JsonHandler";

function SystemOptionJudgementText({option, noAnswerWasSubmitted, isSelectedAnswer}) {
    // Used through the app to display option judgement text correctness
    // in all mobile, tablet and desktop modes

    // PROPERTIES
    const optionIsCorrect = get_nested(['isCorrect'], option, null)

    // BUILD
    if (isNullOrUndefined(option))
        return null
    return (
        <div className="w-full flex justify-center">
          <span className={renderOptionJudgmentColor(optionIsCorrect) + " text-[16px] sm:text-[18px] font-medium"}>
              {renderOptionJudgmentText(optionIsCorrect, noAnswerWasSubmitted, isSelectedAnswer)}
          </span>
        </div>
    );
}

// EXPORT
export default SystemOptionJudgementText