import LandingDesktopTabletUpperFooter from "./LandingDesktopTabletUpperFooter";
import LandingDesktopTabletLowerFooter from "./LandingDesktopTabletLowerFooter";
import SystemCustomDivider from "../../dividers/SystemCustomDivider";

function LandingDesktopFooter({scrollToSolution}) {
    // Used in LandingScreen to display footer
    // in desktop/tablet mode

    // BUILD
    return (
        <div className={"bg-backgroundLight1 w-full flex flex-col space-y-8 px-32 py-40"}>
            <LandingDesktopTabletUpperFooter scrollToSolution={scrollToSolution} />
            <SystemCustomDivider color={'bg-greyLight3'} />
            <LandingDesktopTabletLowerFooter />
        </div>
    );
}

// EXPORT
export default LandingDesktopFooter