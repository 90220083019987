import {isNullOrUndefined} from "../../../services/Validator";

function LandingTabletSectionTitleText({text}) {
    // Used in LandingTabletProblem to display section title text
    // in tablet mode

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[18px] font-bold text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LandingTabletSectionTitleText