import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileMasterSubtitleText({subtitle}) {
    // Used in LandingMobileShowCase to display master
    // subtitle text

    // BUILD
    if(isNullOrUndefined(subtitle))
        return null
    return (
        <div className={"w-full text-center px-12"}>
            <span className={"text-greyLight11 font-medium text-[18px]"}>
               {subtitle}
            </span>
        </div>
    );
}

// EXPORT
export default LandingMobileMasterSubtitleText