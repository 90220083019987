import LandingMobileStepItem from "../../items/landingScreen/LandingMobileStepItem";
import LandingMobileStepDivider from "../../dividers/landingScreen/LandingMobileStepDivider";
import {kLandingIcons} from "../../../constants/icons";
import {kLandingTexts} from "../../../constants/texts";


function LandingMobileWayItWorks() {
    // Used in LandingDesktopSolution to display
    // flash way of working

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-8"}>
            <LandingMobileStepItem icon={kLandingIcons.level}
                                   text={kLandingTexts.solutionMobileZeroLevel} />
            <LandingMobileStepDivider />
            <LandingMobileStepItem icon={kLandingIcons.mode}
                                   text={kLandingTexts.solutionMobileZeroSkill} />
            <LandingMobileStepDivider />
            <LandingMobileStepItem icon={kLandingIcons.answer}
                                   text={kLandingTexts.solutionMobileZeroAnswer} />
            <LandingMobileStepDivider />
            <LandingMobileStepItem icon={kLandingIcons.correction}
                                   text={kLandingTexts.solutionMobileZeroCorrection} />
        </div>
    );
}

// EXPORT
export default LandingMobileWayItWorks