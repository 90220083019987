import LandingBearTracksButton from "../../buttons/landingScreen/LandingBearTracksButton";
import LandingDesktopMasterButton from "../../buttons/landingScreen/LandingDesktopMasterButton";
import LandingDesktopMasterTitleText from "../../texts/landingScreen/LandingDesktopMasterTitleText";
import LandingDesktopMasterSubtitleText from "../../texts/landingScreen/LandingDesktopMasterSubtitleText";
import {kLandingTexts} from "../../../constants/texts";

function LandingDesktopShowCase({onBearTracksClicked}) {
    // Used in LadingScreen to display master content
    // in desktop/tablet mode

    // BUILD
    return (
        <div className={"bg-backgroundLight1 w-full h-[calc(100vh-72px)] min-h-[calc(100vh-72px)] flex flex-col items-center"}>
            <div className={"w-[708px] grow flex flex-col justify-between pb-24"}>
                <div className={"w-full grow flex flex-col space-y-56 justify-center"}>
                    <div className={"w-full flex flex-col space-y-40"}>
                        <LandingDesktopMasterTitleText title={kLandingTexts.punchline} />
                        <LandingDesktopMasterSubtitleText subtitle={kLandingTexts.subPunchline} />
                    </div>
                    <LandingDesktopMasterButton />
                </div>
                <LandingBearTracksButton onBearTracksClicked={onBearTracksClicked} />
            </div>
        </div>
    );
}

// EXPORT
export default LandingDesktopShowCase