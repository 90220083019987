import LandingMobileSectionTitleText from "../../texts/landingScreen/LandingMobileSectionTitleText";
import {isNullOrUndefined} from "../../../services/Validator";

function LandingMobileSectionTitle({icon, text}) {
    // Used in LandingMobileWayItWorks to display section title
    // made of icon and title

    // BUILD
    if (isNullOrUndefined(text))
        return null;
    return (
        <div className={"flex justify-start items-start space-x-6"}>
            <i className="material-icons text-[24px] text-white">
                {icon}
            </i>
            <LandingMobileSectionTitleText text={text} />
        </div>
    );
}

// EXPORT
export default LandingMobileSectionTitle