

// METHODS
export function translateKnowledgeType(knowledgeType) {
    if (knowledgeType === 'Definition') {
        return 'Définition'
    } else if (knowledgeType === 'Convention') {
        return 'Convention'
    } else if (knowledgeType === 'Method') {
        return 'Méthode'
    } else if (knowledgeType === 'Formula') {
        return 'Formule'
    } else if (knowledgeType === 'Theorem') {
        return 'Théorème'
    } else if (knowledgeType === 'Property') {
        return 'Propriété'
    } else {
        return null
    }
}