import {isNullOrUndefined} from "../../services/Validator";
import SystemKnowledgeFrameTitle from "./SystemKnowledgeFrameTitle";
import SystemKnowledgeTypeText from "../texts/SystemKnowledgeTypeText";
import {get_nested} from "../../services/JsonHandler";
import SystemKnowledgeDefinitionText from "../texts/SystemKnowledgeDefinitionText";

function SystemQuestionKnowledge({knowledge, extraClass}) {
    // Used through the app to display question knowledge in
    // all mobile, tablet and desktop modes

    // PROPERTIES
    const knowledgeType = get_nested(['type'], knowledge, null)
    const knowledgeDefinition = get_nested(['definition'], knowledge, null)

    // BUILD
    if (isNullOrUndefined(knowledge) || isNullOrUndefined(knowledgeDefinition))
        return null
    return (
        <div className={"w-full px-16 sm:px-0 mt-24"}>
            <div className={"relative w-full flex flex-col justify-start items-start px-12 md:px-16 py-8 border-primaryLight3 border-[1px] rounded-[12px] " + extraClass}>
                <SystemKnowledgeFrameTitle background={"bg-backgroundLight3"}/>
                <SystemKnowledgeTypeText knowledgeType={knowledgeType} />
                <SystemKnowledgeDefinitionText knowledgeDefinition={knowledgeDefinition} />
            </div>
        </div>
    );
}

// EXPORT
export default SystemQuestionKnowledge