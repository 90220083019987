import {kMethodQuestion} from "../../../constants/objects";
import LandingTabletSectionTitleText from "../../texts/landingScreen/LandingTabletSectionTitleText";
import LandingTabletSectionCoreText from "../../texts/landingScreen/LandingTabletSectionCoreText";
import SystemDraftQuestionCard from "../../cards/SystemDraftQuestionCard";
import {kLandingTexts} from "../../../constants/texts";

function LandingTabletSolutionOne({selectedOption, setSelectedOption}) {
    // Used in LandingTabletSolution to display first block of solution
    // in tablet mode

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 items-start justify-start"}>
            <div className={"w-full flex flex-col space-y-12 items-start justify-start"}>
                <div className={"flex justify-start items-center space-x-8"}>
                    <i className="material-icons text-[32px] text-white">{"bolt"}</i>
                    <LandingTabletSectionTitleText text={kLandingTexts.solutionOneTitle} />
                </div>
                <LandingTabletSectionCoreText text={kLandingTexts.solutionOneText} />
            </div>
            <SystemDraftQuestionCard question={kMethodQuestion}
                                     selectedOption={selectedOption}
                                     setSelectedOption={setSelectedOption} />
        </div>
    );
}

// EXPORT
export default LandingTabletSolutionOne