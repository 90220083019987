import CorrectionStepScreen from "../../../screens/flashScreens/CorrectionStepScreen";
import {useState} from "react";
import ResultsQuestionStatementText from "../../texts/resultsStepScreen/ResultsQuestionStatementText";
import ResultsQuestionItemChevronIcon from "../../icons/resultsStepScreen/ResultsQuestionItemChevronIcon";
import ResultsQuestionDivider from "../../dividers/resultsStepScreen/ResultsQuestionDivider";
import ResultsQuestionIndex from "../../others/resultsStepScreen/ResultsQuestionIndex";
import ResultsQuestionItemLayout from "../../layouts/resultsStepScreen/ResultsQuestionItemLayout";
import {isNullOrUndefined} from "../../../services/Validator";
import {recordPageViewMetric} from "../../../controllers/SystemPageViewMetricController";
import {EventName} from "../../../enums/PageViewMetric";

function ResultsQuestionItem({index, total, question}) {
    // Used in ResultsQuestionsList to display one single
    // question element that leads to correction

    // PROPERTIES
    const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    if (isNullOrUndefined(question))
        return null;
    return (
        <div className="w-full flex flex-col space-y-6">
            {/** CORRECTION MODAL */}
            <CorrectionStepScreen open={isCorrectionModalOpen}
                                  handleClose={() => setIsCorrectionModalOpen(false)}
                                  question={question}
                                  questionIndex={index + 1} />
            {/** CORE */}
            <ResultsQuestionItemLayout setIsHovered={setIsHovered}
                                       openModal={() => openModal()}>
                <ResultsQuestionIndex number={index + 1}
                                      correctness={question?.selectedOption?.isCorrect} />
                <ResultsQuestionStatementText statement={question?.query} />
                <ResultsQuestionItemChevronIcon isHovered={isHovered} />
            </ResultsQuestionItemLayout>
            {/** BOTTOM DIVIDER IF NECESSARY */}
            {index === (total - 1) ? null : <ResultsQuestionDivider />}
        </div>
    );

    // METHODS
    // ---- Open modal
    function openModal() {
        // NAV : Open modal
        setIsCorrectionModalOpen(true)
        // METRICS : Record opening
        recordPageViewMetric(EventName.FLASH_CORRECTION)
    }

}

// EXPORT
export default ResultsQuestionItem