import LandingScreen from "./screens/LandingScreen";

function App() {
  // App component used in index.js as the entry point of Ummia web application

  // BUILD
  return (
    <div className="App">
      <header className="App-header">
          <LandingScreen />
      </header>
    </div>
  );
}

// EXPORT
export default App;
