import LandingDesktopStepItem from "../../items/landingScreen/LandingDesktopStepItem";
import LandingDesktopTabletStepDivider from "../../dividers/landingScreen/LandingDesktopTabletStepDivider";
import {kLandingIcons} from "../../../constants/icons";
import {kLandingTexts} from "../../../constants/texts";


function LandingDesktopWayItWorks() {
    // Used in LandingDesktopSolution to display
    // flash way of working

    // BUILD
    return (
        <div className={"w-full flex justify-between"}>
            <LandingDesktopStepItem icon={kLandingIcons.level}
                                    text={kLandingTexts.solutionDesktopTabletZeroLevel} />
            <LandingDesktopTabletStepDivider />
            <LandingDesktopStepItem icon={kLandingIcons.mode}
                                    text={kLandingTexts.solutionDesktopTabletZeroSkill} />
            <LandingDesktopTabletStepDivider />
            <LandingDesktopStepItem icon={kLandingIcons.answer}
                                    text={kLandingTexts.solutionDesktopTabletZeroAnswer} />
            <LandingDesktopTabletStepDivider />
            <LandingDesktopStepItem icon={kLandingIcons.correction}
                                    text={kLandingTexts.solutionDesktopTabletZeroCorrection} />
        </div>
    );
}

// EXPORT
export default LandingDesktopWayItWorks