import LandingDesktopTabletMainNavigation from "./LandingDesktopTabletMainNavigation";
import {LogoTextSize} from "../../../controllers/SystemUmmiaLogoTextController";
import SystemUmmiaLogoTextImage from "../../images/SystemUmmiaLogoTextImage";

function LandingDesktopTabletUpperFooter({scrollToTop, scrollToSolution}) {
    // Used in LandingDesktopFooter to display
    // current landing navigation links

    // BUILD
    return (
        <div className={"w-full flex items-end justify-between mb-4"}>
            <SystemUmmiaLogoTextImage logoTextSize={LogoTextSize.SMALL}
                                      onLogoPressed={scrollToTop} />
            <LandingDesktopTabletMainNavigation scrollToSolution={scrollToSolution} />
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletUpperFooter