import {isNullOrUndefined} from "../../../services/Validator";

function LandingTabletMasterSubtitleText({subtitle}) {
    // Used in LandingTabletShowCase to display master subtitle text
    // in tablet mode

    // BUILD
    if(isNullOrUndefined(subtitle))
        return null
    return (
        <div className={"w-full text-center px-16"}>
            <span className={"text-greyLight11 font-medium text-[22px]"}>
               {subtitle}
            </span>
        </div>
    );
}

// EXPORT
export default LandingTabletMasterSubtitleText