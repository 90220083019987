
function ResultsMobileTopBarTitleText() {
    // Used in ResultsMobileTopBar to display top bar title
    // in mobile mode

    // BUILD
    return(
        <div className="flex whitespace-nowrap space-x-2 text-[16px] font-medium leading-none">
            <span className="text-greyLight7">{"Résultats"}</span>
        </div>
    );
}

// EXPORT
export default ResultsMobileTopBarTitleText