import {isNullOrUndefined} from "../../../services/Validator";

function ParameterMobileLayout({children}) {
    // Used in GradeStepScreen to frame screen content
    // in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto bg-backgroundLight3 animate-appear w-full grow flex flex-col sm:hidden items-center justify-start"}>
            {children}
        </div>
    );
}

// EXPORT
export default ParameterMobileLayout