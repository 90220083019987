import QuestionsDesktopTabletProgressText from "../../texts/questionsStepScreen/QuestionsDesktopTabletProgressText";
import SystemLinearProgressBar from "../SystemLinearProgressBar";

function QuestionsDesktopTabletProgressBar({currentQuestion, totalQuestion}) {
    // Used in QuestionsDesktopTabletProgressHeader to display flash progress bar in both
    // tablet and progress modes

    // BUILD
    return (
        <div className="sm:w-[240px] md:w-[320px] lg:w-[520px] flex space-x-12 items-center">
            <SystemLinearProgressBar progression={currentQuestion}
                                     total={totalQuestion} />
            <QuestionsDesktopTabletProgressText currentQuestion={currentQuestion}
                                                totalQuestion={totalQuestion} />
        </div>
    );
}

// EXPORT
export default QuestionsDesktopTabletProgressBar