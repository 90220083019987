import {SystemPrimaryCustomButton} from "../SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";
import {routes} from "../../../config/routes";
import {kLandingTexts} from "../../../constants/texts";

function LandingTabletMasterButton() {
    // Used in LandingScreen to display master
    // call-to-action button which is launch flash

    // BUILD
    return (
        <div className={"flex justify-center"}>
            <SystemPrimaryCustomButton
                label={kLandingTexts.callToActionLabel}
                isDisabled={false}
                isLoading={false}
                isFullWidth={false}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                internalLink={routes.flash}
                extraClass={"text-[18px]"}/>
        </div>
    );
}

// EXPORT
export default LandingTabletMasterButton