import {isNullOrUndefined} from "../../../services/Validator";

function ParameterDesktopLayout({children}) {
    // Used in GradeStepScreen to frame screen content
    // in Desktop/Tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto w-[800px] grow hidden lg:flex lg:flex-col justify-start items-center pb-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default ParameterDesktopLayout