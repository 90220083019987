import ResultsCardHeader from "../../others/resultsStepScreen/ResultsCardHeader";
import ResultsDesktopGraphCardLayout from "../../layouts/resultsStepScreen/ResultsDesktopGraphCardLayout";
import ResultsCardHeaderDivider from "../../dividers/resultsStepScreen/ResultsCardHeaderDivider";
import ResultsPieChart from "../../charts/resultsStepScreen/ResultsPieChart";

function ResultsDesktopGraphCard({scores}) {
    // Used in ResultsStepScreen to display flash results in a pie chart

    // BUILD
    return (
        <div className={"w-full h-[280px] min-h-[280px] bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] flex flex-col"}>
            <ResultsCardHeader headerTitle={"Graphiques"} />
            <ResultsCardHeaderDivider />
            <ResultsDesktopGraphCardLayout>
                <ResultsPieChart scores={scores} />
            </ResultsDesktopGraphCardLayout>
        </div>
    );
}

// EXPORT
export default ResultsDesktopGraphCard