import {SystemPrimaryCustomButton} from "../../buttons/SystemCustomButton";
import {kButtonStyles} from "../../../constants/styles";

function QuestionsMobileBottomBar({selectedOption, submitAnswer}) {
    // Used in QuestionsStepScreen in mobile mode to
    // display button for answer validation

    // BUILD
    return (
        <div className={"w-full h-[64px] min-h-[64px] flex sm:hidden justify-center items-start bg-background p-12"}>
            <SystemPrimaryCustomButton
                label={selectedOption !== null ? "Valider ma réponse" : "Choisir une réponse"}
                isDisabled={selectedOption === null}
                isLoading={false}
                isFullWidth={true}
                sizeStyle={kButtonStyles.mediumButtonStyle}
                onButtonClick={() => submitAnswer(selectedOption)}
                extraClass={"w-full h-full"}/>
        </div>
    );
}

// EXPORT
export default QuestionsMobileBottomBar