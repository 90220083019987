import SystemQuestionKnowledge from "../SystemQuestionKnowledge";
import SystemOptionsCard from "../../cards/SystemOptionsCard";
import {get_nested} from "../../../services/JsonHandler";
import CorrectionTabletContentLayout from "../../layouts/correctionStepScreen/CorrectionTabletContentLayout";
import SystemQuestionPicture from "../../images/SystemQuestionPicture";
import CorrectionOptionsLayout from "../../layouts/correctionStepScreen/CorrectionOptionsLayout";
import SystemQuestionCardHeader from "../SystemQuestionCardHeader";
import {
    displayLandscapeOrSquare,
    displayPortrait
} from "../../../controllers/questionsStepScreen/QuestionPictureController";
import SystemQuestionCardQueryText from "../../texts/SystemQuestionCardQueryText";
import {useState} from "react";
import SystemZoomedPictureDialog from "../../dialogs/SystemZoomedPictureDialog";

function CorrectionTabletContent({question, questionIndex}) {
    // Used in CorrectionStepScreen

    // PROPERTIES
    // ---- Modal
    const [isPictureModalOpen, setIsPictureModalOpen] = useState(false)
    // ---- Question
    const questionCorrectness = question?.selectedOption?.isCorrect
    const questionTask = get_nested(['levelA', 'task'], question, null)
    const questionSubtheme = get_nested(['levelA', 'levelASubtheme', 'name'], question, null)
    const questionQuery = get_nested(['query'], question, null)
    const questionKnowledge = get_nested(['levelA', 'levelAKnowledge'], question, null)
    const questionPicture = get_nested(['questionPicture'], question, null)
    const questionPictureUrl = get_nested(['questionPicture', 'filePath'], question, null)
    const questionPictureRatio = get_nested(['questionPicture', 'ratio'], question, null)

    // BUILD
    return (
        <CorrectionTabletContentLayout>
            {/** MODAL */}
            <SystemZoomedPictureDialog open={isPictureModalOpen}
                                       handleClose={() => setIsPictureModalOpen(false)}
                                       pictureUrl={questionPictureUrl}
                                       pictureRatio={questionPictureRatio} />
            {/** CORE */}
            <SystemQuestionCardHeader subtheme={questionSubtheme}
                                      task={questionTask}
                                      questionIndex={questionIndex}
                                      questionCorrectness={questionCorrectness} />
            <SystemQuestionCardQueryText query={questionQuery} />
            {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.LANDSCAPE}*/}
            {/*                                  isForMobile={false} />*/}
            {displayLandscapeOrSquare(questionPicture, questionPictureUrl, questionPictureRatio)
                ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                         pictureRatio={questionPictureRatio}
                                         isForMobile={false}
                                         openZoom={() => setIsPictureModalOpen(true)} />
                : null}
            <SystemQuestionKnowledge knowledge={questionKnowledge} />
            <CorrectionOptionsLayout>
                {displayPortrait(questionPicture, questionPictureUrl, questionPictureRatio)
                    ? <SystemQuestionPicture pictureUrl={questionPictureUrl}
                                             pictureRatio={questionPictureRatio}
                                             isForMobile={false}
                                             openZoom={() => setIsPictureModalOpen(true)} />
                    : null}
                {/*<SystemQuestionPicturePlaceholder pictureRatio={PictureRatio.PORTRAIT}*/}
                {/*                                  isForMobile={false} />*/}
                <SystemOptionsCard question={question} />
            </CorrectionOptionsLayout>
        </CorrectionTabletContentLayout>
    );
}

// EXPORT
export default CorrectionTabletContent