import {isNullOrUndefined} from "../../../services/Validator";

function ResultsTabletContentLayout({children}) {
    // Used in ResultsStepScreen to frame content in tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={'w-[584px] h-[640px] min-h-[640px] hidden lg:hidden sm:flex sm:flex-col items-start justify-center pb-24'}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsTabletContentLayout