import {isNullOrUndefined} from "../../services/Validator";

function SystemFlashSelectedGradeBadge({selectedGrade}) {
    // Used in Subtheme top bars to display selected grade

    // BUILD
    if (isNullOrUndefined(selectedGrade) || isNullOrUndefined(selectedGrade?.name))
        return null
    return (
        <div className={"flex justify-start items-center space-x-6 sm:space-x-12"}>
            {/** DIVIDER */}
            <div className={"h-[16px] min-h-[16px] sm:h-[24px] sm:min-h-[24px] lg:h-[32px] lg:min-h-[32px] w-[1px] min-w-[1px] sm:w-[2px] sm:min-w-[2px] bg-greyLight3"}></div>
            {/** GRADE */}
            <div className={"bg-greyLight1 pb-2 px-6 sm:py-2 sm:px-12 sm:rounded-[4px] rounded-[8px] leading-tight sm:leading-normal"}>
                <span className={"text-greyLight7 text-[12px] sm:text-[16px] lg:text-[20px] font-medium"}>
                    {selectedGrade?.name}
                </span>
            </div>
        </div>
    );
}

// EXPORT
export default SystemFlashSelectedGradeBadge