import {useState} from "react";
import {
    pressDontKnow,
    renderQuestionOptionStyle
} from "../../../controllers/QuestionOptionItemController";
import {isNullOrUndefined} from "../../../services/Validator";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function QuestionsOptionItem({isLast, option, optionSelected, setOptionSelected, onDontKnowPress}) {
    // Used in QuestionsOptionsList to display
    // one single question option item

    // PROPERTIES
    const [isHovered, setIsHovered] = useState(false)

    // BUILD
    if (isNullOrUndefined(option) || isNullOrUndefined(option?.proposal))
        return null
    return(
        <div className={`bg-backgroundLight5 grow flex items-center ${isLast ? "justify-between" : "justify-start"} h-[48px] min-h-[48px] px-12 lg:px-16 leading-none border-1 rounded-[8px] cursor-pointer ${renderQuestionOptionStyle(option, optionSelected, isHovered)}`}
             onClick={isNullOrUndefined(setOptionSelected) ? null : () => setOptionSelected(option)}
             onMouseEnter={() => setIsHovered(true)}
             onMouseLeave={() => setIsHovered(false)}>
                <MathJaxContext>
                    <MathJax inline dynamic>
                        <span className={"text-[18px]"}>
                            {option?.proposal ?? "Erreur"}
                        </span>
                    </MathJax>
                </MathJaxContext>
                {isLast
                    ? <i onClick={(event) => pressDontKnow(event, onDontKnowPress)} className="material-icons text-[18px] lg:text-[20px] text-greyLight7 transition ease-in-out hover:text-greyLight11 duration-100">
                        help
                      </i>
                    : null}
        </div>
    );
}

// EXPORT
export default QuestionsOptionItem