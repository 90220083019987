
function LandingTabletConfusedImage() {
    // Used in LandingTabletProblem to display confusedBear image
    // in tablet mode

    // BUILD
    return (
        <div className={"h-[80px] min-h-[80px] flex justify-center items-center"}>
            <img className={"object-contain w-full h-full"} src={'/images/SystemIceConfused.png'} alt="" />
        </div>
    );
}

// EXPORT
export default LandingTabletConfusedImage