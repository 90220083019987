import {isNullOrUndefined} from "../../../services/Validator";

function ParameterTabletLayout({children}) {
    // Used in GradeStepScreen to frame screen content
    // in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto sm:w-[560px] md:w-[640px] grow hidden sm:flex sm:flex-col lg:hidden items-center justify-start pb-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default ParameterTabletLayout