import ResultsPieChart from "../../charts/resultsStepScreen/ResultsPieChart";

function ResultsGraphCard({scores}) {
    // Used in ResultsStepScreen to display pie chart
    // of current flash

    // BUILD
    return (
        <div className={'h-[168px] min-h-[168px] w-[176px] min-w-[176px] bg-backgroundLight5 shadow-[inset_0px_1px_2px_rgba(82,96,120,1)] rounded-[16px] flex items-center justify-center'}>
                <ResultsPieChart scores={scores} />
        </div>
    );
}

// EXPORT
export default ResultsGraphCard