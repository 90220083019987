

function ResultsDesktopGraphCardLayout({children}) {
    // Used in ResultsDesktopGraphCard to frame graph card
    // content (chart)

    // BUILD
    return (
        <div className={"grow flex items-center justify-center py-16"}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsDesktopGraphCardLayout