import {translateKnowledgeType} from "../../controllers/SystemQuestionKnowledgeController";
import {isNullOrUndefined} from "../../services/Validator";

function SystemKnowledgeTypeText({knowledgeType}) {
    // Used in SystemQuestionKnowledge to display Question
    // knowledge definition to know by heart

    // BUILD
    if (isNullOrUndefined(knowledgeType))
        return null
    return (
        <div className={"flex justify-start items-center space-x-6 text-greyLight5 -ml-2 leading-relaxed"}>
            <i className="material-icons text-[20px]">
                {"toll"}
            </i>
            <span className={"text-[16px] font-medium"}>
                {translateKnowledgeType(knowledgeType)}
            </span>
        </div>
    );
}

// EXPORT
export default SystemKnowledgeTypeText