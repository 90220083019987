import {isNullOrUndefined} from "../../../services/Validator";

function LandingTabletLayout({children}) {
    // Used in LandingScreen to frame content
    // in desktop/tablet mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto bg-backgroundLight1 w-full grow hidden sm:flex sm:flex-col lg:hidden items-center justify-start"}>
            {children}
        </div>
    );
}

// EXPORT
export default LandingTabletLayout