import SystemFlashLogoImage from "../../images/SystemFlashLogoImage";
import {FlashLogoTextSize} from "../../../controllers/FlashLogoTextController";
import SystemDesktopTabletLeaveFlashButton from "../../buttons/SystemDesktopTabletLeaveFlashButton";
import SystemTabletTopBarLayout from "../../layouts/SystemTabletTopBarLayout";
import SystemFlashSelectedGradeBadge from "../../badges/SystemFlashSelectedGradeBadge";
import SystemFlashTopBarLogoLayout from "../../layouts/SystemFlashTopBarLogoLayout";

function ResultsTabletTopBar({selectedGrade, onLeaveFlashPressed}) {
    // Used in ResultsStepScreen to display top bar in tablet mode

    // BUILD
    return (
        <SystemTabletTopBarLayout>
            <SystemFlashTopBarLogoLayout>
                <SystemFlashLogoImage flashLogoSize={FlashLogoTextSize.XSMALL} />
                <SystemFlashSelectedGradeBadge selectedGrade={selectedGrade} />
            </SystemFlashTopBarLogoLayout>
            <SystemDesktopTabletLeaveFlashButton onLeaveFlashPressed={onLeaveFlashPressed} />
        </SystemTabletTopBarLayout>
    );
}

// EXPORT
export default ResultsTabletTopBar