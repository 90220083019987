
// Used through the app to list a set of navigation related
// constants

// ---- About screen sub navigation
export const kAboutNavigation = [
    {
        icon: 'track_changes',
        title: "Notre mission",
        smallTitle: "Mission",
    },
    {
        icon: 'psychology',
        title: "Notre méthode",
        smallTitle: "Méthode",
    },
    {
        icon: 'groups',
        title: "Notre équipe",
        smallTitle: "Équipe",
    },
    {
        icon: 'send',
        title: "Nous contacter",
        smallTitle: "Contact",
    },
]

// ---- Results mobile screen sub navigation
export const kResultsMobileNavigation = [
    {
        icon: 'list',
        title: "Notre méthode",
        smallTitle: "Méthode",
    },
    {
        icon: 'bar_chart',
        title: "Notre mission",
        smallTitle: "Mission",
    },
]