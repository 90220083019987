import {renderLogoTextSize} from "../../controllers/SystemUmmiaLogoTextController";
import {isNullOrUndefined} from "../../services/Validator";


function SystemUmmiaLogoTextImage({logoTextSize, onLogoPressed}) {
    // Used through different header components to show app
    // logo text with a given size

    // BUILD
    return(
        <div className={`${renderLogoTextSize(logoTextSize)} + ${isNullOrUndefined(onLogoPressed) ? "" : " cursor-pointer"}`}
             onClick={onLogoPressed}>
            <img className={"h-full object-contain"} src={'/images/system/ummiaLogoText.png'} alt="Logo" />
        </div>
    );
}

// EXPORT
export default SystemUmmiaLogoTextImage