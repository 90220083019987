import {forwardRef} from "react";
import LandingDesktopSectionTitleText from "../../texts/landingScreen/LandingDesktopSectionTitleText";
import LandingDesktopSectionCoreText from "../../texts/landingScreen/LandingDesktopSectionCoreText";
import LandingDesktopConfusedImage from "../../images/landingScreen/LandingDesktopConfusedImage";
import {kLandingTexts} from "../../../constants/texts";

const LandingDesktopProblem = forwardRef((props, ref) => {
    // Used in LandingScreen to display app problem statement
    // in desktop tablet mode

    // BUILD
    return (
        <div ref={ref}
             className={"w-full flex flex-col items-center justify-start py-56 space-y-24"}>
            <LandingDesktopConfusedImage />
            <LandingDesktopSectionTitleText text={kLandingTexts.problemTitle} />
            <LandingDesktopSectionCoreText text={kLandingTexts.problemText} />
        </div>
    );
})

// EXPORT
export default LandingDesktopProblem