import {isNullOrUndefined} from "../../../services/Validator";

function CorrectionMobileContentLayout({children, extraClass}) {
    // Used in CorrectionScreen to frame content in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"overflow-auto bg-backgroundLight3 w-full grow flex flex-col sm:hidden " + extraClass}>
            {children}
        </div>
    );
}

// EXPORT
export default CorrectionMobileContentLayout