import {renderBearTracksSize} from "../../../controllers/BearTracksButtonController";

function LandingBearTracksButton({onBearTracksClicked, bearTracksSize}) {
    // Used in LandingMobileShowCase to show bear tracks button

    // BUILD
    return (
        <div className={`${renderBearTracksSize(bearTracksSize)} flex justify-center items-center`}>
            <div className={"animate-pulse cursor-pointer h-full min-h-full"}
                 onClick={onBearTracksClicked} >
                <img className={"h-full"} src={'/images/bearTracks.svg'} alt="" />
            </div>
        </div>
    );
}

// EXPORT
export default LandingBearTracksButton