

function LandingDesktopTabletNavigationText({navText, scrollToElement}) {
    // Used in LandingDesktopTabletTopBarNavigation to show screen section and scroll to concerned part

    // BUILD
    return(
        <div onClick={() => scrollToElement()}>
            <span className="cursor-pointer text-[18px] font-medium text-greyLight11 hover:text-white">
                {navText}
            </span>
        </div>
    );
}

// EXPORT
export default LandingDesktopTabletNavigationText