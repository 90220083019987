import {useState} from "react";
import ResultsScorePercentageText from "../../texts/resultsStepScreen/ResultsScorePercentageText";
import ResultsScoreFractionText from "../../texts/resultsStepScreen/ResultsScoreFractionText";


function ResultsTotalScore({correctAnswers, total}) {
    // Used in

    // PROPERTIES
    const [showPercentage, setShowPercentage] = useState(false)

    // BUILD
    return (
        <div className="h-full flex items-center pt-24">
            {showPercentage
                ? <ResultsScorePercentageText correctAnswers={correctAnswers}
                                           total={total}
                                           toggleShowPercentage={() => setShowPercentage(!showPercentage)} />
                : <ResultsScoreFractionText correctAnswers={correctAnswers}
                                            total={total}
                                            toggleShowPercentage={() => setShowPercentage(!showPercentage)} />}
        </div>
    );
}

// EXPORT
export default ResultsTotalScore