
// Used through the app to enable icon choice auto-completion
// and thus reduce typo

// PROPERTIES
// ---- System icons
export const kSystemIcons = {
    cancel: "cancel",
    level: "school",
    logout: "logout",
    error: "error",
    bolt: "bolt",
    task: "adjust",
    theme: "bookmarks",
    support: "support",
    zoomIn: "zoom_in"
}

// ---- Landing screen icons
export const kLandingIcons = {
    settings: "settings",
    level: "school",
    mode: "group_work",
    answer: "wb_incandescent",
    correction: "saved_search",
}