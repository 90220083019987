import {kMethodQuestion} from "../../../constants/objects";
import LandingMobileSectionCoreText from "../../texts/landingScreen/LandingMobileSectionCoreText";
import LandingMobileSectionTitle from "./LandingMobileSectionTitle";
import SystemQuestionKnowledge from "../SystemQuestionKnowledge";
import SystemOptionsCard from "../../cards/SystemOptionsCard";
import {kLandingTexts} from "../../../constants/texts";
import {get_nested} from "../../../services/JsonHandler";

function LandingMobileSolutionTwo() {
    // Used in LandingMobileSolution to display second block
    // of solution in mobile mode

    // PROPERTIES
    const questionKnowledge = get_nested(['levelA', 'knowledge', 'definition'], kMethodQuestion, null)

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 items-start justify-start"}>
            <div className={"w-full flex flex-col space-y-8 items-start justify-start"}>
                <LandingMobileSectionTitle icon={"insights"}
                                           text={kLandingTexts.solutionTwoTitle} />
                <LandingMobileSectionCoreText text={kLandingTexts.solutionTwoText} />
            </div>
            <SystemQuestionKnowledge knowledge={questionKnowledge} />
            <SystemOptionsCard question={kMethodQuestion} />
        </div>
    );
}

// EXPORT
export default LandingMobileSolutionTwo