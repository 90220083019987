import {isNullOrUndefined} from "../../../services/Validator";

function LandingDesktopSectionTitleText({text}) {
    // Used in LandingDesktopProblem to display title text
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(text))
        return null
    return (
        <div className={"whitespace-pre-line"}>
            <span className={"text-[20px] font-bold text-white"}>
                {text}
            </span>
        </div>
    );
}

// EXPORT
export default LandingDesktopSectionTitleText