import {Link} from "react-router-dom";
import {isNullOrUndefined} from "../../../services/Validator";

function LandingDesktopTabletFooterItem({label, route}) {
    // Used in

    // BUILD
    if (isNullOrUndefined(label))
        return null
    return (
        <Link to={route}>
            <div className={"cursor-pointer text-greyLight5 hover:text-greyLight9 leading-tight"}>
                <span className={"text-[18px] font-medium"}>{label}</span>
            </div>
        </Link>
    );
}

// EXPORT
export default LandingDesktopTabletFooterItem