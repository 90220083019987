import LandingMobileStepItemIcon from "../../icons/landingScreen/LandingMobileStepItemIcon";
import LandingMobileStepItemText from "../../texts/landingScreen/LandingMobileStepItemText";

function LandingMobileStepItem({icon, text}) {
    // Used in LandingMobileWayItWorks to display flash step
    // icon and text

    // BUILD
    return (
        <div className={"flex items-center justify-start space-x-12"}>
            <LandingMobileStepItemIcon icon={icon} />
            <LandingMobileStepItemText text={text} />
        </div>
    );
}

// EXPORT
export default LandingMobileStepItem