import {isNullOrUndefined} from "../../../services/Validator";

function ResultsDesktopSummaryLayout({children}) {
    // Used in ResultsStepScreen to frame score summary cards

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-[400px] min-w-[400px] flex flex-col space-y-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsDesktopSummaryLayout