import {isNullOrUndefined} from "../../services/Validator";

function SystemTabletTopBarLayout({children, extraClass}) {
    // Used through the app to frame top bar in mobile mode

    // BUILD
    if (isNullOrUndefined(children))
        return null;
    return (
        <div className={"w-full h-[64px] min-h-[64px] max-h-[64px] w-full hidden sm:flex lg:hidden items-center justify-between px-24 " + extraClass}>
            {children}
        </div>
    );
}

// EXPORT
export default SystemTabletTopBarLayout