import {isNullOrUndefined} from "../../../services/Validator";

function QuestionsCardContentLayout({children}) {
    // Used in QuestionsCard to frame question content
    // in desktop mode

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"w-full h-[304px] min-h-[304px] flex space-x-16 lg:space-x-24 items-start mt-16 sm:mt-24"}>
            {children}
        </div>
    );
}

// EXPORT
export default QuestionsCardContentLayout