import LandingTabletMasterTitleText from "../../texts/landingScreen/LandingTabletMasterTitleText";
import LandingTabletMasterSubtitleText from "../../texts/landingScreen/LandingTabletMasterSubtitleText";
import LandingTabletMasterButton from "../../buttons/landingScreen/LandingTabletMasterButton";
import {kLandingTexts} from "../../../constants/texts";

function LandingTabletFinalCallToAction() {
    // Used in LandingScreen to display final message in tablet mode

    // BUILD
    return (
        <div className={"w-full flex flex-col space-y-24 py-56"}>
            <LandingTabletMasterTitleText title={kLandingTexts.finalCallTitle} />
            <LandingTabletMasterSubtitleText subtitle={kLandingTexts.finalCallText} />
            <LandingTabletMasterButton />
        </div>
    );
}

// EXPORT
export default LandingTabletFinalCallToAction