import {isNullOrUndefined} from "../../../services/Validator";

function ResultsMobileBottomContentLayout({children}) {
    // Used in Results Step screen to frame either
    // questions list or graphs view

    // BUILD
    if (isNullOrUndefined(children))
        return null
    return (
        <div className={"overflow-auto animate-appear bg-backgroundLight5 w-full grow block sm:hidden"}>
            {children}
        </div>
    );
}

// EXPORT
export default ResultsMobileBottomContentLayout