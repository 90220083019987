import {isNullOrUndefined} from "../../services/Validator";
import {MathJax, MathJaxContext} from "better-react-mathjax";

function SystemQuestionCardQueryText({query}) {
    // Used through the app to display
    // Question query text in desktop, tablet and mobile modes

    // BUILD
    if (isNullOrUndefined(query))
        return null
    return (
        <div className={"w-full text-center mt-16 sm:mt-24 px-16 sm:px-24"}>
            <MathJaxContext>
                <MathJax inline dynamic>
                    <span className="whitespace-pre-line font-bold text-[18px] sm:text-[20px] md:text-[22px] text-white">
                        {query ?? "Erreur"}
                    </span>
                </MathJax>
            </MathJaxContext>

        </div>
    );
}

// EXPORT
export default SystemQuestionCardQueryText